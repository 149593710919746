import React from "react"
import { Link } from "react-router-dom"
import StrapiImage from "../../default/strapi/StrapiImage/StrapiImage"
import "./BookBox.scss"

function BookBox({ title, refLinks = [], description, price, images, coverImage, authors, slug, isbn13, magazins, authorText, }) {
    return (
        <Link to={"/buch/" + slug} className="BookBox">

            <StrapiImage image={coverImage} />

            <div className="cont">
                <span className="authorText">
                    {authorText}
                </span>
                <span className="title">
                    {title}
                </span>
                <span className="price">
                    Ab {price}€
                </span>
            </div>


            <i className="fa-regular fa-chevron-right"></i>
        </Link>
    )
}
export default BookBox


export function BookList({ books }) {

    if (books?.data) books = books.data
    if (!Array.isArray(books)) return <p>Keine Bücher hinterlegt.</p>

    return (
        books.map((i, k) => <BookBox {...i.attributes} key={k} />)
    )
}
