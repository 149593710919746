import React, { useState } from "react"
import TextPage from "../../../components/default/pages/TextPage"
import "./RezeptePage.scss"
import RezeptePageInner from "./RezeptePageInner"

function RezeptePage({ }) {

    const [Search, setSearch] = useState("")

    function handleSearch(e) {
        setSearch(e.target.value)
    }

    return (
        <TextPage
            title="Rezepte"
            metaDescription={"Lassen Sie sich von unseren gesunden und leckeren Rezepten inspirieren und finden Sie das Richtige für Sie"}
            useBottomTitle
            headerImage="/rezepte/veganes-gericht_mit_reis_und_zuchini.jpg"
            className="RezeptePage"
            onSearch={setSearch}
            searchText="Rezept suchen"
        // enableSuperBanner
        >
            <RezeptePageInner search={Search} />
        </TextPage>
    )
}
export default RezeptePage