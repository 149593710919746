import React from "react";
import "./MediaCarousel.scss";


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import { Pagination } from "swiper";
import "swiper/scss";
import "swiper/scss/pagination";
import { STRAPI } from "../../../const/Endpoints";


function MediaCarousel({ data = [], title }) {

    if (!data || data.length == 0) return <div>{title}</div>
    return (
        <div className="MediaCarousel">
            <Swiper pagination={true} modules={[Pagination]} >
                {data.map((i, k) => <SwiperSlide key={k}><Item {...i} /></SwiperSlide>)}
            </Swiper>
        </div>
    )
}
export default MediaCarousel

function Item({ id, attributes }) {
    let { formats, alternativeText, name, caption, width, height, mime, url } = attributes || {}


    switch (mime) {
        case "image/jpeg":
        case "image/png":
            {
                // let url = formats?.medium?.url
                return <img className="w-100" src={STRAPI + url} alt={alternativeText} />
            }
            break;
        case "video/mp4":
            {

                return <video width="100%" height="100%" controls>
                    <source src={STRAPI + url} type="video/mp4" />
                    <source src={STRAPI + url} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            }
            break;
    }

}