import React from "react"
import "./PowerdBy.scss"

function PowerdBy({ }) {
    return (
        <a href="https://dashdesign.eu/#reform-leben" className="PowerdBy" target="_blank">
            <span>Webdesign mit </span>
            <div className="heartOut">
                <div className="heart" />
            </div>
            <span>von</span>
            <img alt="dashdesign; (Grünberg) Logo" src="https://blog.dashdesign.eu/wp-content/uploads/2021/04/cropped-dashdesign.png" />
        </a>
    )
}
export default PowerdBy