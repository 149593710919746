import React from "react"
import ColoredBox from "../../../box/home/ColoredBox"
import WelcomeCarousel from "../../../box/home/WelcomeCarousel"
import WelcomeTextBox from "../../../box/home/WelcomeTextBox"
import NewsList from "../../../box/news/NewsList"
import StatisticBox from "../../../box/StatisticBox/StatisticBox"
import "./BoxPage.scss"

function BoxPage({ className, boxes = [] }) {

    function GetBox({ box }) {
        let { type, payload } = box
        let Component = <h2>404 Box not Found</h2>
        switch (type) {
            case "WELCOME":
                Component = <WelcomeCarousel {...payload} />
                break;
            case "STATISTIC":
                Component = <StatisticBox {...payload} />
                break;
            case "WelcomeTextBox":
                Component = <WelcomeTextBox {...payload} />
                break;
            case "ColoredBox":
                Component = <ColoredBox {...payload} />
                break;
            case "News":
                Component = <div className="container"><NewsList {...payload} /></div>
                break;
            case "Custom":
                Component = payload
                break;
            default:
                Component = <h2>404</h2>
                break;
        }
        return Component
    }

    return (
        <div className={"BoxPage " + className}>

            {boxes.map((i, k) =>
                <GetBox box={i} key={k} />
            )}

        </div >
    )
}
export default BoxPage