import React from "react"
import { Link } from "react-router-dom"
import "./NetworkErrorPage.scss"

function NetworkErrorPage({ error }) {

    let url = window.location.href

    return (
        <div className="NetworkErrorPage">
            <div className="Page404">
                <div style={{ maxWidth: 900 }} className="container">

                    <span>
                        <i className="fa-solid fa-triangle-exclamation"></i>
                    </span>
                    <h1>Fehler beim laden dieser Seite</h1>
                    <span style={{ opacity: 0.5 }}>Errorcode: 404</span>

                    <hr></hr>
                    <p>Es tut uns Leid, aber die gesuchte Seite "<b>{url}</b>" funktioniert nicht. Diese Seite existiert nicht, wurde gelöscht oder es gibt Verbindungsprobleme. </p>

                    <Link to="/">
                        <button>Weiter zur Startseite</button>
                    </Link>

                    <hr></hr>

                    <small style={{ opacity: 0.12 }}>Debug-Infos: {error || "NO_MSG"}, {url}</small>

                </div>
            </div>
        </div>
    )
}
export default NetworkErrorPage