import React from "react"
import HTML from "../../../default/more/HtmlRenderer/HtmlRenderer"
import GhostLoader_ContentOverview from "../../../loader/GhostLoader/GhostLoader_ContentOverview/GhostLoader_ContentOverview"
import "./ContentOverview.scss"

const ChapterUriId = "kapitel-"

function ContentOverview({ html, loading = false }) {

    let contentOverview = getOverview(`${html}`)



    if (!contentOverview || contentOverview.length == 0) return <div><p className="p-4">Kein Inhalt verfügbar.</p></div>
    return (
        <div className="ContentOverview p-3">
            <h2>Inhalt:</h2>
            {
                loading ?
                    <GhostLoader_ContentOverview />
                    :
                    contentOverview.map((i, k) =>
                        <a key={k} aria-label="Springt zur Überschrift im Artikel" className="item" href={"#" + ChapterUriId + k}><HTML>{i}</HTML></a>
                    )
            }
        </div>
    )
}
export default ContentOverview


function getOverview(html) {
    let headings = []
    let list = html.split("</h2>")

    list.forEach(element => {
        let next = element.split("<h2>")
        let nextPush = next[1]
        if (nextPush) headings.push(nextPush)
    });

    return headings
}