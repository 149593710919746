import React from "react"
import "./BorderRadiusBox.scss"

function BorderRadiusBox({ children, className, style, bg, innerBorderRadius }) {
    return (
        <div className={"BorderRadiusBox  " + className} style={style}>
            {/* <div className="bg">{bg}</div> */}
            <div className={innerBorderRadius ? "innerBorderRadius inner" : "inner"}>
                {children}
            </div>
        </div>
    )
}
export default BorderRadiusBox