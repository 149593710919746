import React from "react";
import "./NewsImage.scss";

function NewsImage({ betterImage, alt, wrapperClassName }) {

    let images = betterImage?.media_details?.sizes

    let src = betterImage?.media_details?.sizes?.full?.source_url ||
        betterImage?.media_details?.sizes?.medium?.source_url ||
        betterImage?.media_details?.sizes?.thumbnail?.source_url || ''

    if (!src) return null
    return (
        <div className={wrapperClassName}>
            <img src={src} className={"NewsImage"} alt={alt || "Artikelbild ohne Nutzen"} />
        </div>
    )
}
export default NewsImage