import React from "react"
import TextPage from "../../../components/default/pages/TextPage"
import "./AboutUsPage.scss"

function AboutUsPage({ }) {
    return (
        <TextPage
            headerImage="/über/yoga_mit_reform-tshirt-am-see.jpg"
            className="AboutUsPage"
            title="Über uns"
            useBottomTitle
            metaDescription={"Lernen Sie uns kennen und entdecken Sie unsere Vision "}
        >
            <div className="mb-5">
                <hr />
            </div>

            <div className="mb-5">
                <h2>Welche Themen erwarten Sie?</h2>
                <p>Wir können uns vor vielen Krankheiten schützen – länger gesund und jung bleiben!</p>
                <p>Von der Schulmedizin noch weitgehend unbeachtet liefern wissenschaftliche Studien immer häufiger Hinweise darauf, dass viele Infektionen, schwere Krankheiten aber auch vorzeitiges Altern vermeidbar sind.  Einige bekannte Naturstoffe leisten hier Erstaunliches.</p>
                <p>Bei uns erfahren Sie was Sie tun können, um sich bestmöglich vor Krankheiten zu schützen und bis ins hohe Alter gesund und leistungsfähig zu sein.</p>
            </div>

            <div className="mb-5">
                <h2>Wo können Sie <span style={{ color: "var(--main)" }}>reform</span>leben beziehen?</h2>
                <p>Als gedrucktes Magazin erhalten Sie <b>reform<span style={{ color: "var(--sub)" }}>leben</span></b> alle zwei Monate (ungerade) i.d.R. kostenfrei in allen teilnehmenden Reformwarenhäusern* oder als Jahresabonnement zum Preis von 19 € direkt zu Ihnen nach Hause.</p>
            </div>


        </TextPage >
    )
}
export default AboutUsPage