import React from "react"
import StaticImage from "../../images/StaticImage"
import "./WelcomeTextBox.scss"

function WelcomeTextBox({ title, subTitle, text, suffix, image, height }) {
    return (
        <div className="WelcomeTextBox p-5 ">
            <div className="container p-5 px-0">
                <div className="row align-items-center">
                    <div className="col-12 col-md-7">
                        <div className="pb-4">
                            <div className="mb-4">
                                <h2>{title}</h2>
                                {subTitle && <span className="subTitle">{subTitle}</span>}
                                <hr />
                            </div>
                            {/* <small>by Dr. Mohr</small> */}
                            <div className="pt-3">{text}</div>

                        </div>
                        {suffix}
                    </div>
                    <div className="col-12 col-md-5 ">
                        {image ?
                            <StaticImage {...image} />
                            : null}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WelcomeTextBox