import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { SpeechString } from "../../../../functions/Speech"
import StaticImage from "../../../images/StaticImage"
import "./HeaderCook.scss"

function HeaderCook({ title = "Schritt für Schritt" }) {

    const navigate = useNavigate()
    const [Audio, setAudio] = useState(true)
    const [Microphone, setSetMicrophone] = useState(false)


    function setMicrophone(value) {
        setSetMicrophone(value)

        if (Audio) {
            SpeechString(value ? "Sprachsteuerung aktiv" : "Sprachsteuerung deaktiviert")
        }
    }

    function leave() {
        navigate(-1)
    }

    useEffect(() => {
    }, [Microphone, Audio])

    return (
        <div className="HeaderCook ">
            <>
                <div className="d-flex align-items-baseline justify-content-between px-2 pb-1">
                    <StaticImage className="logo" uri="/logo/reformleben.png" />

                    <div className="title text-center p-2">
                        <span className="receptTitle">{title}</span>
                        {/* <StaticImage className="logo" uri="/logo/konradLogo2.svg" /> */}
                    </div>

                    <button onClick={leave} className="clear leave">
                        Abbrechen
                    </button>
                </div>

                <hr className="mt-0" />


                <div className="d-flex align-items-baseline justify-content-between px-2 pb-1">
                    <div></div>
                    <div className="options">
                        <MicrophoneButton value={Microphone} onChange={() => setMicrophone(!Microphone)} />
                        <AudioButton value={Audio} onChange={() => setAudio(!Audio)} />
                    </div>
                </div>

            </>
        </div >
    )
}
export default HeaderCook

function AudioButton({ value, onChange = () => null }) {

    useEffect(() => {

        if (value == true) {
            SpeechString("Audioausgabe aktiviert.")
        }
    }, [])

    return (
        <button onClick={onChange} className={"AudioButton " + value}>
            {value ?
                <i className="fa-solid fa-volume"></i>
                :
                <i className="fa-solid fa-volume-xmark"></i>

            }
        </button>
    )
}

function MicrophoneButton({ value, onChange = () => null }) {

    return (
        <button onClick={onChange} className={"AudioButton " + value}>
            {value ?
                <i className="fa-solid fa-microphone"></i>
                :
                <i className="fa-solid fa-microphone-slash"></i>
            }
        </button>
    )
}