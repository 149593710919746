import React, { useState } from "react";
import "./CookieBanner.scss";

const trackingId = "G-L93L14MZGK";

const CookieBanner = () => {

    const [Display, setDisplay] = useState(true)

    const acceptCookies = () => {
        document.cookie = "cookies_accepted=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
        hideCookieBanner();
        checkCookieConsent();
    }

    const declineCookies = () => {
        document.cookie = "cookies_accepted=false; expires=Fri, 31 Dec 9999 23:59:59 GMT";
        hideCookieBanner();
    }

    const hideCookieBanner = () => {
        // Hier könnten Sie Code einfügen, um den Cookie-Banner auszublenden, z.B. indem Sie ihm eine "hidden"-Klasse hinzufügen
        setDisplay(false)
    }

    if (!Display || true) return null
    return (
        <div className="CookieBanner">
            <p>Diese Website verwendet Cookies, um Ihnen eine bessere Benutzererfahrung zu bieten. Durch die Nutzung dieser Website stimmen Sie der Verwendung von Cookies zu.</p>
            <button onClick={acceptCookies}>Akzeptieren</button>
            <button onClick={declineCookies}>Ablehnen</button>
        </div>
    );
}
export default CookieBanner;

const checkCookieConsent = () => {
    // const cookiesAccepted = document.cookie.includes("cookies_accepted=true");
    // if (cookiesAccepted) {
    //     // Google Analytics-Code
    //     function gtag() {
    //         window.dataLayer = window.dataLayer || [];
    //         window.dataLayer.push(arguments);
    //     }
    //     const script = document.createElement('script');
    //     script.async = true;
    //     script.src = `https://www.googletagmanager.com/gtag/js?id=` + trackingId;
    //     script.onload = () => {
    //         gtag('js', new Date());
    //         gtag('config', trackingId);
    //     }
    //     document.head.appendChild(script);

    //     console.log("jfnjf")
    // }
}
