import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import CookieBanner from './components/default/law/CookieBanner/CookieBanner';
import AdBlockDetector from './container/more/AdBlockDetector/AdBlockDetector';
import { GenerateAntiXssLog } from './functions/GenerateAntiXssLog';
import MainNavigation from './navigation/MainNavigation/MainNavigation';


function App() {
  GenerateAntiXssLog()

  return (
    <BrowserRouter>


      <CookieBanner />

      <AdBlockDetector />

      {/* <CookiePopup></CookiePopup> */}
      {/* <Provider store={store}> */}
      <MainNavigation />
      {/* </Provider> */}
    </BrowserRouter>
  );
}
export default App;
