import React from "react"
import "./Search404.scss"

function Search404({ searchText }) {
    return (
        <div className="Search404">
            <h3>Nicht fündig geworden?</h3>
            <p>Es wurden keine mit "{searchText}" übereinstimmenden Ergebnisse gefunden.</p>

            <div className="mt-2">
                <p className="mb-0">Vorschläge:</p>
                <p>
                    <li>Achte darauf, dass alle Wörter richtig geschrieben sind.</li>
                    <li>Probiere es mit anderen Suchbegriffen.</li>
                    <li>Probiere Sie es mit allgemeineren Suchbegriffen.</li>
                </p>
            </div>
        </div>
    )
}
export default Search404