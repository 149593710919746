const validCodes = ['KONRAD17', 'REO-OFU-361', 'REO_TKQ_147', 'PARTNER_DR_WOLZ'];

export function hasValidAccessCode() {
    const accessCode = getAccessCodeFromCookie()
    return validCodes.includes(accessCode.toUpperCase());
}

export function getAccessCodeFromCookie() {
    const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith('accessCode='))
        ?.split('=')[1];
    return (cookieValue ?? '');
}

export function setAccessCodeInCookie(accessCode) {
    if (isCodeValid(accessCode)) {
        document.cookie = `accessCode=${accessCode.toUpperCase()}`;
    }
}

export function isCodeValid(newCode) {
    return validCodes.includes(newCode.toUpperCase());
}