import { useLayoutEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import '../../App.scss';
import Footer from '../../components/menus/Footer/Footer';
import AblaufplanPage from '../../container/AblaufplanPage/AblaufplanPage';
import AuthorPage from '../../container/AuthorPage/AuthorPage';
import BookListPage from '../../container/BookListPage/BookListPage';
import BookPage from '../../container/BookPage/BookPage';
import CategoryPage from '../../container/CategoryPage/CategoryPage';
import HomePage from '../../container/HomePage/HomePage';
import NewsArticlePage from '../../container/News/NewsArticlePage/NewsArticlePage';
import NewsArticlePageQr from '../../container/News/NewsArticlePageQr/NewsArticlePageQr';
import NewsPage from '../../container/News/NewsPage/NewsPage';
import RezeptCookPage from '../../container/Rezepte/RezeptCookPage/RezeptCookPage';
import RezeptCookPageStep from '../../container/Rezepte/RezeptCookPageStep/RezeptCookPageStep';
import RezeptQrPage from '../../container/Rezepte/RezeptQrPage/RezeptQrPage';
import RezeptSinglePage from '../../container/Rezepte/RezeptSinglePage/RezeptSinglePage';
import RezeptePage from '../../container/Rezepte/RezeptePage/RezeptePage';
import ServerStatusPage from '../../container/ServerStatusPage/ServerStatusPage';
import DatenschutzPage from '../../container/law/DatenschutzPage/DatenschutzPage';
import ImpressumPage from '../../container/law/ImpressumPage/ImpressumPage';
import MagazineInfoPage from '../../container/magazine/MagazineInfoPage/MagazineInfoPage';
import MagazineItemPage from '../../container/magazine/MagazineItemPage/MagazineItemPage';
import MagazinePage from '../../container/magazine/MagazinePage/MagazinePage';
import MagazineReaderPage from '../../container/magazine/MagazineReaderPage/MagazineReaderPage';
import AboutUsPage from '../../container/more/AboutUsPage/AboutUsPage';
import KontaktPage from '../../container/more/KontaktPage/KontaktPage';
import MaintenancePage from '../../container/more/MaintenancePage/MaintenancePage';
import MediaDataPage from '../../container/more/MediaDataPage/MediaDataPage';
import OrderPage from '../../container/more/OrderPage/OrderPage';
import PageRedirectOr404, { Page404 } from '../../container/more/PageRedirectOr404/PageRedirectOr404';
import HeaderNavigation from '../../navigation/HeaderNavigation/HeaderNavigation';

const ScrollToTopWrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
}

function MainNavigation() {

    return (

        <ScrollToTopWrapper>
            <div className="App">
                <HeaderNavigation />

                <div className="pages">
                    <Routes>
                        <Route index element={<MagazinePage />} />
                        <Route path="/about" element={<HomePage />} />

                        <Route path="/neuigkeiten" element={<NewsPage />} />

                        <Route path="/magazin" element={<MagazinePage />} />


                        <Route path="/magazin/info" element={<MagazineInfoPage />} />
                        <Route path="/magazin/bestellen" element={<OrderPage />} />

                        {/* <Route path="/basisprogramm" element={<BasisprogrammPage />} /> */}
                        <Route path="/ueber-uns" element={<AboutUsPage />} />

                        {/* Primär Intern  */}
                        <Route path="/serverstatus" element={<ServerStatusPage />} />
                        <Route path="/ablaufplan" element={<AblaufplanPage />} />
                        <Route path="/mediadaten" element={<MediaDataPage />} />
                        <Route path="/kontakt" element={<KontaktPage />} />
                        {/* Rechltiches */}
                        <Route path="/impressum" element={<ImpressumPage />} />
                        <Route path="/datenschutz" element={<DatenschutzPage />} />

                        <Route path="" />


                        {/* //* Dynamische Inhalte // */}
                        {/* Artikel */}
                        <Route path="/p/:slug" element={<NewsArticlePage />} />
                        <Route path="/p/:slug/qr" element={<NewsArticlePageQr />} />



                        <Route path="/magazin/ausgabe/:id" element={<MagazineItemPage />} />
                        <Route path="/magazin/ausgabe/:id/read" element={<MagazineReaderPage />} />


                        {/* Geht noch nicht. */}
                        <Route path="/buch/:slug" element={<BookPage />} />
                        <Route path="/buch/" element={<Page404 />} />
                        <Route path="/bücher" element={<BookListPage />} />
                        <Route path="/buchempfehlungen" element={<BookListPage />} />

                        {/* Rezepte */}
                        <Route path="rezepte">
                            <Route path=":slug" >
                                <Route path="kochen" element={<RezeptCookPageStep />}>
                                    <Route path="schritt" element={<RezeptCookPageStep />}>
                                        <Route index element={<RezeptCookPageStep />} />
                                    </Route>
                                    <Route index element={<RezeptCookPage />} />
                                </Route>
                                <Route index element={<RezeptSinglePage />} />
                            </Route>
                            <Route path=":slug/qr" element={<RezeptQrPage />} />
                            <Route index element={<RezeptePage />} />
                        </Route>

                        {/* Authoren */}
                        <Route path="/author/:id" element={<AuthorPage />} />
                        <Route path="/author/:id/:fullNameIrrelevant" element={<AuthorPage />} />
                        <Route path="/author/:id/*" element={<AuthorPage />} />

                        {/* //* ENDE: Inhalte // */}



                        <Route path="/kategorie/:uri" element={<CategoryPage />} />


                        {/* Fehler Seiten  */}
                        <Route path="/wartung" element={<MaintenancePage />} />
                        <Route path="*" element={<PageRedirectOr404 />} />
                    </Routes>
                </div>

                <Routes>
                    <Route
                        path="/rezepte/:slug/kochen/*"
                        element={null} />

                    <Route
                        path="*"
                        element={
                            <Footer />
                        } />
                </Routes>
            </div>
        </ScrollToTopWrapper>

    );
}
export default MainNavigation;
