import axios from "axios"
import React, { useEffect, useState } from "react"
import TextPage from "../../components/default/pages/TextPage"
import GhostLoader from "../../components/loader/GhostLoader"
import "./ServerStatusPage.scss"

let URLS = [
    {
        title: "Shop",
        url: "https://shop.reformleben.de/wp-json/wp/v2",
        description: "Unser Webshop."
    },
    {
        title: "API",
        url: "https://api.reformleben.de/",
        description: "Datenverteiler für Rezepte, Formulare und alles weitere",
        isOffline: true
    },
    {
        title: "Fallback API",
        url: "https://strapi1.dashdesign.eu/",
        description: "2. Datenverteiler für Rezepte, Formulare und alles weitere"

    },
    {
        title: "News API",
        url: "https://shop.reformleben.de/wp-json/wp/v2",
        description: "Datenverteiler für Neuigkeiten"
    },
    {
        title: "Mitgliederbereich",
        url: "https://mitglieder.reformleben.de/wp-json/wp/v2",
        description: "Unser Mitgliederbereich",
        isOffline: true
    },
]

function ServerStatusPage({ }) {
    return (
        <TextPage
            metaDescription={"Sehen Sie sich den aktuellen Serverstatus an und bleiben Sie über mögliche Ausfälle informiert"}
            headerImage="/more/serverstatus.jpg"
            title="Server-Status"
            className="ServerStatusPage" >
            <ServerStatusList urls={URLS} />
        </TextPage >
    )
}
export default ServerStatusPage


function ServerStatusList(props) {
    const [status, setStatus] = useState({});
    const [firstLoad, setFirstLoad] = useState(false)
    const [countdown, setCountdown] = useState(9)

    useEffect(() => {
        async function fetchData() {
            for (const url of props.urls) {
                try {
                    const res = await axios.get(url.url);
                    setStatus((prevStatus) => ({ ...prevStatus, [url.url]: res.status }));
                    setFirstLoad(true)
                } catch (err) {
                    setStatus((prevStatus) => ({ ...prevStatus, [url.url]: 'Error' }));
                }
            }
            setCountdown(9);
        }

        const interval = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
            if (countdown === 0) {
                fetchData();
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [countdown, props.urls]);


    if (!firstLoad) return <GhostLoader />
    return (
        <div>
            <p className="mb-5">Der Status aktualisiert sich in {countdown >= 0 ? (countdown + " Sekunden") : "jetzt"}  automatisch{countdown >= 0 ? "." : "..."}</p>
            <div>
                {props.urls.map((url, k) => (
                    <div key={k} className="d-flex flex-row mb-4">
                        {url.isOffline ?
                            <div className={"dot yellow"}></div>
                            :
                            <div className={status[url.url] === 200 ? "dot" : "dot red"}></div>
                        }
                        <div className="ps-2">
                            <h3>{url.title} {url.isOffline && <small>(DEAKTIVIERT)</small>}</h3>

                            <p>{url.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
