import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Document, Page } from 'react-pdf';
import "./MagazineReaderPage.scss";

function MagazineReaderPage() {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfFile, setPdfFile] = useState(null);

    useEffect(() => {
        const fetchPdfFile = async () => {
            try {
                const response = await fetch(
                    "https://strapi1.dashdesign.eu/uploads/HZOE_REF_2023_01_web_40c86cefee.pdf"
                );
                const blob = await response.blob();
                setPdfFile(URL.createObjectURL(blob));
            } catch (error) {
                console.error("Failed to fetch PDF file:", error);
            }
        };

        fetchPdfFile();
    }, []);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <div className="MagazineReaderPage">
            <Helmet>
                <title>Ausgabe 49 – Online Version</title>
            </Helmet>

            <embed src="https://strapi1.dashdesign.eu/uploads/HZOE_REF_2023_01_web_40c86cefee.pdf" type="application/pdf" width="100%" height="600px" />

            {pdfFile ? (
                <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} />
                </Document>
            ) : (
                <div>Loading PDF...</div>
            )}
        </div>
    );
}

export default MagazineReaderPage;
