import React from "react"
import { Link } from "react-router-dom"
import { STRAPI } from "../../../const/Endpoints"
import { formartDuration } from "../../../functions/formartDuration"
import BorderRadiusBox from "../../default/boxes/BorderRadiusBox/BorderRadiusBox"
import "./RezeptPageItem.scss"

function RezeptPageItem({ id, attributes }) {

    let { title, slug, createdAt, description, steps, durationInMinutes, workDurationInMinutes, images } = attributes


    let coverImageUrl = ""
    let imagesList = images?.data || []
    if (imagesList != []) {
        coverImageUrl = imagesList[0]?.attributes?.formats?.medium?.url || ""
        coverImageUrl = imagesList[0]?.attributes?.url
    }

    let imageDivProps = {
        className: "imageBox ratio ratio-16x9",
        style: {
            backgroundImage: `url(${STRAPI + coverImageUrl})`
        }
    }

    const moreInfoUri = "/rezepte/" + slug
    const cookUrl = "/rezepte/" + slug + "/kochen"

    return (
        <div className="col-12 col-md-6 col-xl-4 p-2">
            <BorderRadiusBox className="m-0 RezeptPageItem">
                <Link to={moreInfoUri} state={{ attributes: attributes }} >
                    <div {...imageDivProps} />
                </Link>
                <div className="p-2">
                    <h3 className="title">{title || "Ka"}</h3>
                    <div>
                        <div>
                            <i className="fa-solid fa-watch"></i>
                            <span title="Gesamtdauer" className="pr-2"> Gesamtdauer: {formartDuration(durationInMinutes, "")} </span>
                        </div>
                        <div>
                            <i className="fa-solid fa-person-carry-box"></i>
                            <span title="Arbeitszeit"> Arbeitszeit:  {formartDuration(workDurationInMinutes)} </span>
                        </div>
                    </div>
                    <div className=" actionButtons">
                        <Link to={moreInfoUri} className="p-1">
                            <button className="main">Öffnen <i className="fa-solid fa-angle-right"></i></button>
                        </Link>
                        {/* <Link to={cookUrl} className="p-1">
                            <button className="main">
                                <i className="fa-solid fa-hat-chef"></i>
                                Jetzt kochen
                            </button>
                        </Link> */}
                    </div>
                </div>
            </BorderRadiusBox>
        </div>
    )
}
export default RezeptPageItem

