import React from "react";
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import StaticImage from "../../images/StaticImage";
import "./WelcomeCarousel.scss";

function WelcomeCarousel({ title, subTitle, image, suffix, height }) {
    return (
        <div className="WelcomeCarousel">
            <StaticImage asBackground uri={image} height={height} >
                <div className="inner align-items-end" >
                    <div className="container pb-0 pb-md-5 ">
                        <div className="mb-4 mt-5">
                            <h1>{title}</h1>
                            <span className="subTitle">{subTitle}</span>
                        </div>
                        <div>
                            {suffix}
                        </div>
                    </div>
                </div>
            </StaticImage>
        </div>
    )
}
export default WelcomeCarousel