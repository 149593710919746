import React from "react"
import getSrcFromSingleComponent from "../../../../functions/strapi/getSrcFromSingleComponent"
import BorderRadiusBox from "../../../default/boxes/BorderRadiusBox/BorderRadiusBox"
import StaticImage from "../../../images/StaticImage"
import "./InstagramQrCode.scss"

function InstagramQrCode({ image, subTitle, title, qrCodeImage, url = "https://www.instagram.com/reformleben/" }) {
    return (
        <a className="InstagramQrCode" href={url} target={"_blank"} rel="nofollow" aria-label="Weiter zu Instagram">
            <BorderRadiusBox innerBorderRadius className=" d-none d-md-block">


                <StaticImage
                    className={"bg"}
                    height={360}
                    asBackground
                    url={getSrcFromSingleComponent(image)}
                >
                    <StaticImage className="qrCode" url={getSrcFromSingleComponent(qrCodeImage)} />
                </StaticImage>

                <div className="p-3 mb-0 content">
                    <h3 className="mb-1">{title}</h3>
                    <p className="mb-0">{subTitle}</p>
                </div>
                {/* <div className="p-4  content">
                <p className="mb-0">Mehr von uns gibt's auf Instagram!</p>
            </div> */}
            </BorderRadiusBox>
        </a>
    )
}
export default InstagramQrCode