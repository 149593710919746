import React from "react"
import { Helmet } from "react-helmet"
import TextPage from "../../../components/default/pages/TextPage"
import "./ImpressumPage.scss"

function ImpressumPage({ }) {
    return (
        <TextPage
            metaDescription={"Hier finden Sie unsere Kontaktdaten und Informationen zu unserem Unternehmen"}
            useBottomTitle
            title="Impressum"
            headerImage="/more/datenschutzInDeutschland.jpg"
            className="ImpressumPage"
        >

            <Helmet>
                <script type="application/javascript"> var UC_UI_SUPPRESS_CMP_DISPLAY=true;</script>
            </Helmet>
            <div className="container">


                <div>
                    <p>
                        <b>zoe Media-Verlag GmbH</b><br />
                        Brunhildestr. 34<br />
                        61389 Schmitten<br />
                    </p>

                    <p>
                        <b>Geschäftsführung:</b> Bernhard Sillich
                    </p>

                    <p>
                        <b>Telefonnummer:</b> +49 6082 922900 – 0<br />
                        <b>Faxnummer:</b> +49 6082 922900 – 9<br />
                        <b>E-Mailadresse:</b> info@reformleben.de<br />
                    </p>

                    <p>
                        Registergericht & Registernummer: Amtsgericht Königstein: HRB 9614
                        Umsatzsteuer-Identifikationsnummer: DE 298 300 270
                    </p>

                    <p>
                        Inhaltlich verantwortlich: Bernhard Sillich, Adresse wie oben.
                    </p>

                    <p>
                        Dieses Impressum gilt für diese Website, alle genutzten Sub-Domains, sowie für alle unsere Social-Media-Auftritte.
                    </p>

                    <p>
                        Plattform der Europäischen Kommission zur Online-Streitbeilegung (OS) für Verbraucher: https://ec.europa.eu/consumers/odr/. Wir sind nicht bereit und nicht verpflichtet an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                    </p>
                </div>
            </div>


            <div>Version: 0.1</div>
        </TextPage >
    )
}
export default ImpressumPage