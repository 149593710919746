import { STRAPI } from "../../const/Endpoints"

export default function getSrcFromSingleComponent(singleImageObj) {
    if (!singleImageObj) return null

    let { url, alternativeText, caption, width, height } = singleImageObj?.data?.attributes || {}
    let http = STRAPI
    let imgUrl = http + url


    if (!imgUrl) return null

    return imgUrl
}