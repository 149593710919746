import React from "react"
import "./ShareButtons.scss"

function ShareButtons({ text, title, url, showBorder, heading }) {



    const platforms = [
        {
            title: "Facebook",
            icon: <i className="fa-brands fa-facebook" />,
            url: `https://www.facebook.com/sharer/sharer.php?href=${url}`,
        },
        {
            title: "Email",
            icon: <i className="fa-solid fa-at" />,
            url: `mailto:?subject=${title}&body=${text}`,
        },
        {
            title: "Twitter",
            icon: <i className="fa-brands fa-twitter" />,
            url: `https://twitter.com/share?url=${url}&text=${text}`,
        },
        {
            title: "WhatsApp",
            icon: <i className="fa-brands fa-whatsapp" />,
            url: `https://api.whatsapp.com/send/?text=${text}`,
        },
        // {
        //     title: "Instagram",
        //     icon: <i className="fa-brands fa-instagram" />,
        //     url: `https://twitter.com/share?url=${url}&text=${text}`,
        // },
    ]

    return (
        <div className={"ShareButtons " + (showBorder ? "showBorder" : "")}>
            {heading && <span className="heading">{heading}</span>}
            <div className="links">
                {platforms.map((i, k) =>
                    <a key={k} target={"_blank"} href={i.url}>
                        {i.icon}
                    </a>
                )}
            </div>
        </div>
    )
}
export default ShareButtons
