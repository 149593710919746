import React from "react";
import { useNavigate } from "react-router-dom";
import "./MaintenancePage.scss";

function MaintenancePage({ }) {

    const navigate = useNavigate();

    return (
        <div className="Page Page404">
            <div style={{ maxWidth: 900 }} className="container">
                <span>
                    <i className="fa-solid fa-sparkles"></i>
                </span>
                <h1>Wartungsarbeiten</h1>
                <span style={{ opacity: 0.5 }}>#503</span>
                <hr></hr>
                <p>Entschuldigen Sie bitte, dieser Bereich wird aktuell überarbeitet. <br />Bitte versuchen Sie es später erneut.</p>
                <hr></hr>
                <>
                    <button className="main" onClick={() => navigate(-1)}>Zurück</button>
                </>
            </div>
        </div>
    )
}
export default MaintenancePage