import React from "react"
import { Link } from "react-router-dom"
import BorderRadiusBox from "../../../default/boxes/BorderRadiusBox/BorderRadiusBox"
import StaticImage from "../../../images/StaticImage"
import "./BuyPrintVersion.scss"

function BuyPrintVersion({ title, uri, }) {
    return (
        <Link to={uri}>
            <BorderRadiusBox innerBorderRadius className={"BuyPrintVersion"}>
                <div className="content">
                    <StaticImage height={360} asBackground uri="/magazin/girl_with_no_46.jpg" />

                    <div className="inner2" />
                    <div className="inner">
                        <h2>{title}</h2>
                        <div className="icon">
                            <i className="fa-solid fa-angles-right"></i>
                        </div>

                    </div>
                </div>
            </BorderRadiusBox>
        </Link>
    )
}
export default BuyPrintVersion