import axios from "axios";


export default async function searchArticle(searchText) {
    const endpoint = "https://shop.reformleben.de/wp-json/wp/v2/posts";
    const filter = `?search=${searchText}`;
    const config = {
        method: 'get',
        url: endpoint + filter,
        headers: { 'Content-Type': 'application/json' },
        cacheDurationInMinutes: 0,
    };

    try {
        const response = await axios(config);
        return response.data || [];
    } catch (error) {
        console.error(error);
        return [];
    }
}
