import React, { useState } from "react"
import BorderRadiusBox from "../../../default/boxes/BorderRadiusBox/BorderRadiusBox"
import SearchInput from "../../../default/inputs/SearchInput/SearchInput"
import Search from "../../../menus/Header/Search/Search"
import "./SearchBoxWithText.scss"

function SearchBoxWithText({ text, title, }) {

    const [SearchText, setSearchText] = useState("")
    const [SearchIsOpen, setSearchIsOpen] = useState(false)

    function handleSearch(str) {
        setSearchText(str)
        setSearchIsOpen(true)
    }

    function handleClose() {
        setSearchIsOpen(false)
        setSearchText("")
    }


    return (
        <BorderRadiusBox className="SearchBoxWithText">
            <div className="p-3 content">
                <i className="fa-solid fa-search round"></i>

                <h3>{title}</h3>
                <p>{text}</p>
                <SearchInput onSearch={handleSearch} value={SearchText} isOpen={SearchIsOpen} onClose={handleClose} />
                <Search isOpen={SearchIsOpen} searchText={SearchText} onSearch={handleSearch} onClose={handleClose} />
            </div>
        </BorderRadiusBox>
    )
}
export default SearchBoxWithText