import React from "react"
import RegisterToNewsletter from '../../../api/RegisterToNewsletter/RegisterToNewsletter'
import BorderRadiusBox from "../../../default/boxes/BorderRadiusBox/BorderRadiusBox"
import "./JoinNewsletterBox.scss"

const zohoApiKeyOLD = "b15d305aefc743b7866bc5390de13015"

//Cleint ID: 1000.3DN3185E2KK17WBDH3EUJUGGLGYOSU
//Reformleben.de – 29 December 2022
// const zohoApiKeyKA = "fcdaee03369154db9d18f5ba530a59c6172e17ebed"
const ZohoApiKey = "b185bb9a7cc425eee0ab1869962b5f01e9f9830828"

const SourceName = "reformleben.de/magazin"
const listKey = "25823000001347004"


function JoinNewsletterBox({ inputText, title, subTitle, agbText }) {




    // useEffect(() => {
    //     const script = document.createElement('script');

    //     script.src = "https://zoem-zcmp.maillist-manage.eu/js/optin.min.js";
    //     script.onload = setupSF('sf3z8e2bad03d628c3768cfacec09e728ea8b260222a25ff2a01e48689d8ab1753d7', 'ZCFORMVIEW', false, 'light', false, '0')
    //     script.async = true;

    //     document.body.appendChild(script);

    //     return () => {
    //         document.body.removeChild(script);
    //     }
    // }, []);



    //* Fallback Newsletter
    if (true) {
        return (
            <BorderRadiusBox className="JoinNewsletterBox">

                <div className='p-3'>
                    <i className="fa-solid fa-newspaper round"></i>

                    <h3 >{title}</h3>
                    <p>{subTitle}</p>
                    <NewsletterInner inputText={inputText} />
                    {/* <iframe height={100} src="./iframeNewsletter.html" /> */}
                </div>

            </BorderRadiusBox>
        )
    }
    return (
        <BorderRadiusBox className="JoinNewsletterBox">
            <div className='p-3'>
                <i className="fa-solid fa-newspaper round"></i>

                <h3 >{title}</h3>
                <p>{subTitle}</p>

                <RegisterToNewsletter placeholderText={inputText} agbText={agbText} />
            </div>
        </BorderRadiusBox>
    )
}
export default JoinNewsletterBox


//Woher kommt der Code?
//Zoho Camapin iFrame -> Code holen -> Kein Script -> CSS Raus

function NewsletterInner({ inputText }) {
    // return <iframe width={"100%"} height="300px" src={"./iframeNewsletter.html"} />
    return (
        <div
            className="quick_form_9_css"
            name="SIGNUP_BODY"
        >
            <div>
                <div
                    id="SIGNUP_HEADING"
                />

                <form
                    method="POST"
                    id="zcampaignOptinForm"
                    action="https://zoem-zcmp.maillist-manage.eu/weboptin.zc"
                    target="_zcSignup"
                    onSubmit={() => {
                        window.zcScptlessSubmit(this);
                    }}
                >
                    {/* <div
                        id="errorMsgDiv"
                    >
                        Fehler.
                    </div> */}
                    <div

                        className="SIGNUP_FLD"
                    >
                        <input
                            autoComplete='email'
                            type="text"
                            placeholder={inputText || "Ihre Email"}
                            changeitem="SIGNUP_FORM_FIELD"
                            name="CONTACT_EMAIL"
                            id="EMBED_FORM_EMAIL_LABEL"
                        />
                    </div>
                    <div
                        className="SIGNUP_FLD"
                    >
                        <input
                            type="submit"
                            className='button main'
                            name="SIGNUP_SUBMIT_BUTTON"
                            id="zcWebOptin"
                            value={"Jetzt anmelden"}
                        />
                    </div>
                    <input type="hidden" id="fieldBorder" defaultValue="" />
                    <input
                        type="hidden"
                        id="submitType"
                        name="submitType"
                        defaultValue="optinCustomView"
                    />
                    <input
                        type="hidden"
                        id="emailReportId"
                        name="emailReportId"
                        defaultValue=""
                    />
                    <input
                        type="hidden"
                        id="formType"
                        name="formType"
                        defaultValue="QuickForm"
                    />
                    <input type="hidden" name="zx" id="cmpZuid" defaultValue="14ac3737b0" />
                    <input type="hidden" name="zcvers" defaultValue={2.0} />
                    <input
                        type="hidden"
                        name="oldListIds"
                        id="allCheckedListIds"
                        defaultValue=""
                    />
                    <input
                        type="hidden"
                        id="mode"
                        name="mode"
                        defaultValue="OptinCreateView"
                    />
                    <input
                        type="hidden"
                        id="zcld"
                        name="zcld"
                        defaultValue="15bbde1b61d7dd0"
                    />
                    <input type="hidden" id="zctd" name="zctd" defaultValue="" />
                    <input type="hidden" id="document_domain" defaultValue="" />
                    <input
                        type="hidden"
                        id="zc_Url"
                        defaultValue="zoem-zcmp.maillist-manage.eu"
                    />
                    <input type="hidden" id="new_optin_response_in" defaultValue={0} />
                    <input type="hidden" id="duplicate_optin_response_in" defaultValue={0} />
                    <input
                        type="hidden"
                        name="zc_trackCode"
                        id="zc_trackCode"
                        defaultValue="ZCFORMVIEW"
                    />
                    <input
                        type="hidden"
                        id="zc_formIx"
                        name="zc_formIx"
                        defaultValue="3z31a1072180778ef841fcf1f942d089370a641b2a0bcc55622e2fcd8ee16c82c8"
                    />
                    <input type="hidden" id="viewFrom" defaultValue="URL_ACTION" />
                    <input
                        type="hidden"
                        id="scriptless"
                        name="scriptless"
                        defaultValue="yes"
                    />
                    <input
                        type="hidden"
                        id="zc_spmSubmit"
                        name="zc_spmSubmit"
                        defaultValue="ZCSPMSUBMIT"
                    />
                </form>
            </div>
        </div>
    )
}
