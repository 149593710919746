import React, { useState } from "react"
import { Link, useLocation } from "react-router-dom"
import StaticImage from "../../../images/StaticImage"
import "./HeaderMenu.scss"

import CategorySelection from "../../../box/news/CategorySelection/CategorySelection"
import Url from "../../../default/buttons/Url/Url"
import SearchInput from "../../../default/inputs/SearchInput/SearchInput"
import Search from "../Search/Search"

const switchMenuBreakpoint = "sm"

function HeaderMenu({ hideSearchbar }) {
    const [SearchText, setSearchText] = useState("")
    const [SearchIsOpen, setSearchIsOpen] = useState(false)
    const [MobileMenuOpen, setMobileMenuOpen] = useState(false)

    function handleSearch(str) {
        setSearchText(str)
        setSearchIsOpen(true)
    }

    function handleClose() {
        setSearchIsOpen(false)
        // setSearchText("")
    }

    function toggleMobileMenu() {
        setMobileMenuOpen(!MobileMenuOpen)
    }

    return (<>
        <div className="HeaderMenu d-print-none ">
            {/* <div className="sub"></div> */}
            <div className="blur"></div>


            <div className="inner">
                <div className="d-flex flex-row align-items-center justify-content-between  px-4 p-2 w-100">


                    <div className="col-7">
                        <div className="d-flex flex-column ">
                            {/* Logo */}
                            <Link to="/">
                                <StaticImage
                                    alt={"reformleben-logo"}
                                    className="logo"
                                    uri="/logo/logo_light.png"
                                    uriDarkMode="/logo/logo_dark.png"
                                />
                            </Link>


                            {/* Desktop Menü */}
                            <div className={`d-${switchMenuBreakpoint}-flex d-none flex-column`}>
                                <div className="d-flex flex-row align-items-center mb-2 ">
                                    <Links cont={navigation2} />

                                </div>

                                <div className="d-flex flex-row align-items-center">
                                    <Links cont={navigation} />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={`col-5 align-items-center d-${switchMenuBreakpoint}-flex d-none`}>
                        <div className="d-flex flex-column align-items-end justify-content-center">
                            {/* Suchbar */}
                            {!hideSearchbar && <SearchInput onSearch={handleSearch} value={SearchText} isOpen={true} onClose={handleClose} />}

                            <div className="mt-3">
                                <CategorySelection />
                            </div>
                        </div>
                    </div>


                    <div className={` d-${switchMenuBreakpoint}-none d-block`}>
                        <button className="clear" onClick={toggleMobileMenu} aria-label={"Menü umkehren"}>
                            {MobileMenuOpen ?
                                <span><i className="fa-solid fa-xmark"></i></span>
                                :
                                <div><i className="fa-solid fa-solid fa-bars"></i></div>
                            }
                        </button>

                    </div>

                </div>
            </div>
            <div className="blur"></div>
            <div className="sub"></div>
            <MobileMenu isVisible={MobileMenuOpen} onLinkClick={() => setMobileMenuOpen(false)} />
        </div>
        <div className="HeaderMenuPush "></div>
        <Search isOpen={SearchIsOpen} searchText={SearchText} onSearch={handleSearch} onClose={handleClose} />
    </>
    )
}
export default HeaderMenu

function MobileMenu({ isVisible, onLinkClick }) {
    return (
        <div className={isVisible ? `MobileMenu isVisible` : "MobileMenu"}>
            <Links onLinkClick={onLinkClick} cont={[...navigation, ...navigation2]} />

            <hr />
            <h2>Kategorien:</h2>
            <CategorySelection />
        </div>
    )
}

function Links({ searchComponent, cont, onLinkClick }) {
    const [ShowMenu, setShowMenu] = useState(false)


    function toggleMenu() {
        setShowMenu(!ShowMenu)
    }

    function handleLinkPress() {
        setShowMenu(false)
    }

    return (
        <>
            <div className={`d-none d-${switchMenuBreakpoint}-block Links`}>
                {cont.map((i, k) =>
                    <HeaderLink onClick={onLinkClick} {...i} key={k} />
                )}
                {searchComponent}
            </div>


            <div className={`d-block d-${switchMenuBreakpoint}-none LinksMobile`}>
                {cont.map((i, k) =>
                    <HeaderLink onClick={onLinkClick} {...i} key={k} />
                )}
                {searchComponent}
            </div>
        </>
    )
}

function HeaderLink(props) {
    let { link, title, icon, linkType } = props
    let content = title
    let tooltip

    const location = useLocation();

    function handleClick() {
        props.onClick && props.onClick()
    }

    //Wenn man auf der URL ist, wird die "Pill" angezeigt
    let active = link == location.pathname

    return (
        <Url onClick={handleClick} title={tooltip} className={`${linkType + ""?.toString()} ${active && "true"}`} to={link}>
            <div className={"activePill " + active} />
            <div>
                <span className={linkType != "SMALL" && "d-sm-none d-inline-block"}>{icon}</span>
                {content}
            </div>
        </Url>
    )
}

const navigation = [
    {
        title: "Startseite",
        link: "/",
        icon: <i className="fa-solid fa-home"></i>,

    },
    {
        title: "Über uns",
        link: "/about",
        icon: <i className="fa-solid fa-user-doctor-message"></i>,

    },
    // {
    //     title: "Basisprogramm",
    //     link: "/basisprogramm",
    // },
    {
        title: "Rezepte",
        link: "/rezepte",
        icon: <i className="fa-solid fa-hat-chef"></i>,

    },
    {
        title: "Kontakt",
        link: "/kontakt",
        icon: <i className="fa-solid fa-envelope"></i>,

    },

    {
        icon: <i className="fa-solid fa-shopping-bag"></i>,
        title: "Shop",
        link: "https://shop.reformleben.de",
    },
]


const navigation2 = [

    // {
    //     linkType: "SMALL",
    //     icon: <i className="fa-solid fa-shopping-bag"></i>,
    //     title: "Shop",
    //     link: "https://shop.reformleben.de",
    // },
    // {
    //     linkType: "ICON",
    //     icon: <i className="fa-solid fa-user"></i>,
    //     title: "Login",
    //     link: "https://mitglieder.reformleben.de",
    // },
]