import React from "react"
import RezeptPageItem from "../../../components/box/rezepte/RezeptPageItem"
import Search404 from "../../../components/default/search/Search404/Search404"
import GhostLoader_Article from "../../../components/loader/GhostLoader_Article"
import { useRecipeSearch } from "../../../services/recipes/useRecipeSearch"
import "./RezeptePage.scss"

function RezeptePageInner({ search }) {
    let { Data, Loading, Error } = useRecipeSearch(search)

    if (Error) return <p>Fehler bei der Suche.</p>
    if (Loading) return <GhostLoader_Article />
    if (Data) {
        let { data, meta } = Data || {}
        let { page, pageSize, pageCount, total } = meta?.pagination || {}
        return (
            <>
                <SearchResultsLengthText length={data?.length} searchText={search} />
                <div className="rezept row">
                    {data.map((i, k) =>
                        <RezeptPageItem {...i} key={k} />
                    )}
                </div>
            </>
        )
    }
}
export default RezeptePageInner

function SearchResultsLengthText({ length, searchText }) {
    if (!searchText) return <p style={{ opacity: 0 }}>⁄</p>
    if (!length || length == 0) {
        return <Search404 searchText={searchText} />
    }
    return <p>{length} Rezepte gefunden</p>
}