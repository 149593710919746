import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import RezeptePageInner from "../../../../container/Rezepte/RezeptePage/RezeptePageInner"
import GeneralSearchPage from "../../../../container/search/GeneralSearchPage/GeneralSearchPage"
import PostSearchPage from "../../../../container/search/PostSearchPage/PostSearchPage"
import GlobalSearch from "../../../../services/search/GlobalSearch"
import "./Search.scss"

const types = [
    {
        title: "Artikel",
        component: <PostSearchPage />
    },
    {
        title: "Rezepte",
        component: <RezeptePageInner />
    },
    // {
    //     title: "Autoren",
    // },
    {
        title: "Allgemeine Seiten",
        component: <GeneralSearchPage />
    },
    // {
    //     title: "Reformhäuser",
    // },
]
function Search(props) {
    if (!props.searchText || props.searchText == "") return null
    return <SearchInner {...props} />
}

function SearchInner({ isOpen = false, onClose = () => null, onSearch = () => { }, searchText = "", }) {

    const location = useLocation()

    const [Type, setType] = useState()
    const [Results, setResults] = useState({})
    const [SearchStarted, setSearchStarted] = useState(false)

    const handleSearchEvent = (e) => onSearch(e?.target?.value)
    const handleClose = () => {
        onClose()
    }

    async function handleSearchStart(st) {
        try {
            const resultsPromise = GlobalSearch(st);
            const results = await resultsPromise;
            setResults(results);
            setSearchStarted(true);
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        handleSearchStart(searchText)
        handleClose()
    }, [location])

    return (
        <div className={"Search isOpen"}>
            <div className="inner">


                <div className="d-flex flex-row justify-content-between align-items-start header">

                    {/* Suche nach & "Nur im Bereich XY" Anzeige */}
                    <div>
                        <Link to="#" onClick={handleClose}><i className="fa-regular fa-chevron-left" /> Zurück</Link>
                        <div className="mb-3">
                            <h3 className="SearchInfoText m-0">Suche nach <input
                                onChange={handleSearchEvent}
                                value={searchText}
                                placeholder="Suchbegriff eingeben"
                            /></h3>
                            {Type ? <span className="SearchInfoSubText">Nur im Bereich: {Type}</span> : null}
                        </div>
                    </div>

                    {/* Close button */}
                    <button onClick={handleClose} className="Close clear" aria-label="Suchen">
                        <i className="fa-solid fa-xmark-circle"></i>
                    </button>
                </div>


                <h2>Entschuldigen Sie bitte, die Suche wird aktuell überarbeitet. <br />Bitte versuchen Sie es morgen erneut.</h2>
                {/* <p><b>Hinweis:</b> Aktuell dauert die Suche ungewöhnlich lange. Wir arbeiten bereits an einer Lösung. Danke für Ihre Geduld.</p>


                <div>
                    {Results instanceof Promise ? (
                        <p>Lädt...</p>
                    ) : (
                        <div>
                            <SearchHits {...Results} />
                        </div>
                    )}
                </div> */}

            </div>
        </div>
    )
}
export default Search

function SearchHits({ hits, categories = [] }) {
    return (
        <div className="SearchHits">
            <h1>{hits} Treffer</h1>
            <div>
                {categories.map((i, k) => <div key={k}>

                    <h2>
                        <i className={`fa-solid fa-${i.icon}`} />
                        {" " + i.title}
                    </h2>

                    <div>
                        {i.items.map((ii, kk) => <div>
                            <p>{JSON.stringify(ii)}</p>
                        </div>)}
                    </div>


                </div>)}
            </div>
        </div>
    )
}



function TypeSelector({ types: [], onTypeChange }) {

    const [Selection, setSelection] = useState()

    function handleSelect(s) {
        let newVal = s

        //Wenn man die Auswahl zurück nimmt
        if (Selection == s) {
            newVal = null
        }

        onTypeChange(newVal)
        setSelection(newVal)
    }


    return <div className="TypeSelector">
        {types.map((i, k) =>
            <div
                key={k}
                className={i.title == Selection ? "Type isActive" : "Type"}
                onClick={() => handleSelect(i.title)}
            >
                <span>{i.title}</span>
            </div>
        )}
    </div>
}