import React from "react"
import TextPage from "../../../components/default/pages/TextPage"
import "./OrderPage.scss"

function OrderPage({ }) {
    return (
        <TextPage
            title="Magazin bestellen"
            useBottomTitle
            className="OrderPage"
            headerImage="/magazin/reform-leben-paket.jpg"
        >
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>

            <a href="https://shop.reformleben.de/" target={"_blank"}>
                <button>Weiter zum Webshop</button>
            </a>
        </TextPage>
    )
}
export default OrderPage