import React from "react"
import "./CookButton.scss"

function CookButton({ cookUrl }) {

    function handleClick() {
        alert("Diese Funktion ist leider noch nicht verfügbar.")

    }

    return (
        <a style={{ opacity: 0.5, }} onClick={handleClick} aria-label="Rezept im Kocheprogram öffnen" >
            {/*< Link to={cookUrl} */}
            <button className="CookButton main d-print-none">
                {/* <i className="fa-solid fa-hat-chef"></i> */}
                <div className="d-flex flex-column">
                    <div>
                        <i className="fa-solid fa-hat-chef"></i> Jetzt kochen
                    </div>
                    <small>Bald verfügbar.</small>
                </div>
            </button>
        </a>
    )
}
export default CookButton           