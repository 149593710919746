import React from "react"
import GhostLoader from "./GhostLoader"
import "./GhostLoader_Article.scss"
import GhostLoader_Text from "./GhostLoader_Text"

function GhostLoader_Article({ }) {
    return (
        <div className="GhostLoader_Article mb-5 p-3">
            <div className="d-flex flex-row align-items-center">
                <div className="col">

                    <div className="mb-0">
                        <GhostLoader_Text words={4} height={22} borderRadius={8} />
                    </div>

                    <div className="mb-2">
                        {/* <GhostLoader width={28} height={28} borderRadius={9} /> */}
                        <GhostLoader width={128} height={12} borderRadius={4} />
                        <GhostLoader width={92} height={12} borderRadius={12} />
                    </div>

                    <GhostLoader_Text words={20} height={12} />
                </div>
                <div className="col">
                    <GhostLoader width={"100%"} height={160} borderRadius={4} />
                </div>
            </div>
        </div>
    )
}
export default GhostLoader_Article