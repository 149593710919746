import axios from "axios"
import React, { useEffect, useState } from "react"
import InfiniteScroll from 'react-infinite-scroll-component'
import { POST_ENDPOINT } from "../../../const/Endpoints"
import BorderRadiusBox from "../../default/boxes/BorderRadiusBox/BorderRadiusBox"
import GhostLoader_Article from "../../loader/GhostLoader_Article"
import NewsItem from "./NewsItem"
import "./NewsList.scss"

const NEWS_ENDPOINT = POST_ENDPOINT
const LIMIT = 8;
const DEFAULT_CLASSES = "NewsItem col-12  p-1 "
const LOADER = <div className="d-flex flex-column">
    <div className={DEFAULT_CLASSES} >
        <BorderRadiusBox className={"mb-0"}>
            <GhostLoader_Article />
        </BorderRadiusBox>
    </div>
    <div className={DEFAULT_CLASSES}>
        <BorderRadiusBox className={"mb-0"}>
            <GhostLoader_Article />
        </BorderRadiusBox>

    </div>
</div>

function NewsList({ searchText, categories, itemClassName = DEFAULT_CLASSES, endpointSuffix = "", hideArticle = "slug-xy", hideSearchText }) {
    const [articles, setArticles] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const offset = articles.length;

    function fetchArticles(searchText2, categories2) {
        let url = `${NEWS_ENDPOINT}?per_page=${LIMIT}&offset=${offset}${endpointSuffix}`;

        if (searchText2) {
            const replacedSearchText = replaceUmlauts(searchText2);
            url += `&search=${replacedSearchText}`;
        }
        if (categories2 && categories2.length > 0) {
            url += `&categories=${categories2.join(',')}`;
        }

        let fetcher = axios

        fetcher({ url: url, ttl: 0, })
            .then(response => {
                const data = response.data;
                setArticles(prevArticles => [...prevArticles, ...data]);
                setHasMore(data.length === LIMIT);
            })
    }

    // useEffect(() => {
    //     fetchArticles(searchText);
    // }, [categories]);

    useEffect(() => {
        setArticles([])
        setHasMore(true)
        fetchArticles(searchText, categories);
    }, [searchText, categories, endpointSuffix]);

    return (
        <div className="NewsList">
            {searchText && !hideSearchText && articles.length >= 1 && <p>Mindestens {articles.length} Artikel gefunden.</p>}
            <InfiniteScroll
                dataLength={articles.length}
                next={fetchArticles}
                hasMore={hasMore}
                loader={LOADER}
                className="d-flex flex-row flex-wrap align-items-stretch justify-content-stretch"
                id={searchText}
            >
                {articles.map((article, k) => (
                    article.slug != hideArticle && <NewsItem className={itemClassName} {...article} key={k} />
                ))}
            </InfiniteScroll>

        </div>
    );
}
export default NewsList


function replaceUmlauts(text) {
    const umlautMap = {
        "ä": "ae",
        "ö": "oe",
        "ü": "ue",
        "Ä": "Ae",
        "Ö": "Oe",
        "Ü": "Ue",
        "ß": "ss"
    };

    return text.replace(/[äöüÄÖÜß]/g, match => umlautMap[match]);
}



// const ArticleList = () => {
//     const [articles, setArticles] = useState([]);
//     const [page, setPage] = useState(1);
//     const [hasMore, setHasMore] = useState(true);
//     const [filter, setFilter] = useState();

//     useEffect(() => {
//         loadArticles();
//     }, []);

//     const loadArticles = () => {
//         let url = NEWS_ENDPOINT + `?per_page=10&page=${page}`
//         if (filter) {
//             url += `&${filter.type}=${filter.slug}`;
//         }

//         console.log("get " + url)


//         let fetchManager = filter ? axios : alenaxios

//         fetchManager({ url: url })
//             .then(response => response.data)
//             .then(posts => {
//                 setArticles(articles.concat(posts));
//                 setPage(page + 1);
//                 if (posts.length === 0) {
//                     setHasMore(false);
//                 }
//             });
//     }

//     const updateFilter = (type, slug) => {
//         console.log("update filter")
//         setFilter({ type, slug });
//         setPage(1);
//         setHasMore(true);
//         setArticles([]);
//     }

//     return (
//         <InfiniteScroll
//             className="ArticleList row"
//             dataLength={articles.length}
//             next={loadArticles}
//             hasMore={hasMore}
//             loader={<div className="row">
//                 <div className="NewsItem col-12 col-md-6 col-xxl-4 p-3" >
//                     <GhostLoader_Article />
//                 </div>
//                 <div className="NewsItem col-12 col-md-6 col-xxl-4 p-3" >
//                     <GhostLoader_Article />
//                 </div>
//             </div>}
//             endMessage={
//                 <p style={{ textAlign: 'center' }}>
//                     <b>Yay! You have seen it all</b>
//                 </p>
//             }
//         >
//             <div>
//                 <h3>Filter test:</h3>
//                 {cats.map((i, k) =>
//                     <button key={k} onClick={() => updateFilter('categories', i)}>{i}</button>
//                 )}
//             </div>

//             {articles.map((article, k) => (
//                 <NewsItem className="NewsItem col-12 col-md-6 col-xxl-4" {...article} key={k} />
//             ))}
//         </InfiniteScroll>
//     )
// }
// export default ArticleList

// let cats = ["10"]
