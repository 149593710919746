import React from "react"
import { Link } from "react-router-dom"
import getSrcFromSingleComponent from "../../../functions/strapi/getSrcFromSingleComponent"
import BorderRadiusBox from "../../default/boxes/BorderRadiusBox/BorderRadiusBox"
import "./BasisProgrammBox.scss"

function BasisProgrammBox({ image, title, subTitle, buttonText, uri }) {

    let altText = `${title}: ${subTitle}`
    return (
        <BorderRadiusBox className="BasisProgrammBox">
            <hr></hr>
            <h2 >{title}</h2>
            <p>{subTitle}</p>
            <hr />
            <img alt={altText} className="w-100" src={getSrcFromSingleComponent(image)} />
            <Link to={uri}>
                <button className="main w-100 ">
                    {buttonText}
                </button>
            </Link>
        </BorderRadiusBox>
    )
}
export default BasisProgrammBox