import React from "react"
import GhostLoader_Text from "../../GhostLoader_Text"
import "./GhostLoader_ContentOverview.scss"

const size = 24

function GhostLoader_ContentOverview({ }) {
    return (
        <div className="GhostLoader_ContentOverview">
            <GhostLoader_Text height={size} words={3} />
            <GhostLoader_Text height={size} words={3} />
            <GhostLoader_Text height={size} words={2} />
            <GhostLoader_Text height={size} words={2} />
        </div>
    )
}
export default GhostLoader_ContentOverview