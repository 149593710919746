export function SpeechString(string, speed = 1) {
    if (!string) return

    var msg = new SpeechSynthesisUtterance();
    var voices = window.speechSynthesis.getVoices();
    msg.text = string;
    // msg.voice = voices[10];
    msg.volume = 1; // From 0 to 1
    // msg.rate = speed; // From 0.1 to 10
    // msg.pitch = 0.8; // From 0 to 2
    msg.lang = 'de';


    window.speechSynthesis.speak(msg);

}