import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BookList } from "../../components/box/BookBox/BookBox";
import TitleImageBox from "../../components/box/TitleImageBox/TitleImageBox";
import AuthorBoxBig from "../../components/box/news/AuthorBoxBig/AuthorBoxBig";
import NewsList from "../../components/box/news/NewsList";
import BorderRadiusBox from "../../components/default/boxes/BorderRadiusBox/BorderRadiusBox";
import { GetStrapiImageUrl } from "../../components/default/strapi/StrapiImage/StrapiImage";
import ErrorText from "../../components/loader/ErrorText";
import GhostLoader from "../../components/loader/GhostLoader";
import { useApi } from "../../services/useApi";
import { Page404 } from "../more/PageRedirectOr404/PageRedirectOr404";
import "./CategoryPage.scss";

function CategoryPage({ }) {

    let { uri } = useParams();
    let [categoryData, setCategoryData] = useState(null);
    let { Data, Loading, Error } = useApi("/categories?filters[uri][$eq]=" + uri + "&populate[0]=titleImage&populate[1]=specialists&populate[2]=specialists.profileImage&populate[3]=books&populate[4]=books.coverImage")

    useEffect(() => {
        if (Data) {
            let category = Data?.data[0]?.attributes
            setCategoryData(category);
        }
    }, [uri, Data]);

    if (Loading) return <GhostLoader />
    if (Error) return <ErrorText />
    if (categoryData) {
        let { title, titleImage, icon, specialists, books, text, introduction } = categoryData

        return (
            <div className="CategoryPage">
                <TitleImageBox source_url={GetStrapiImageUrl(titleImage, "large")} />

                <div className="container px-4">

                    <div className="row mb-5">
                        <div className="col-12 col-md-7" style={{ marginTop: -200 }}>


                            <div className="mb-5 pb-5">

                                <i className={`fa-solid titleIcon fa-` + icon} />
                                <hr />
                                <h1>{title}</h1>

                                {/* //Sonderseite bei essen & Trinken */}
                                {uri == "essen-und-trinken" && <div className="d-flex">
                                    <button className="">Zu unseren Rezepten <i className="fa-solid fa-chevron-right"></i></button>
                                </div>}

                                <p><b>{introduction || `Zeitnah finden Sie hier alles zum Thema ${title}.`}</b></p>

                                {/* <h2>Mehr zu dem Thema</h2> */}
                                <small>{text}</small>
                            </div>


                            {/* Beiträge */}
                            <div>
                                <h2 className="">Beiträge</h2>
                                <NewsList searchText={title} hideSearchText />
                            </div>
                        </div>
                        <div className="col-12 col-md-4 offset-md-1">

                            <div>
                                <BorderRadiusBox className="p-3">
                                    <h2>Spezialisten</h2>
                                    {specialists?.data?.map((i, k) =>
                                        <AuthorBoxBig authorID={i.attributes?.wordpressID} key={k} />
                                    )}
                                </BorderRadiusBox>

                                <BorderRadiusBox className="p-3">
                                    <h2>Bücher</h2>
                                    <BookList books={books} />
                                </BorderRadiusBox>

                                {/* <AdBanner /> */}
                            </div>
                        </div>
                    </div>





                </div>
            </div>
        )
    } else {
        return <Page404 />
    }
}
export default CategoryPage
