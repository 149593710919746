import React from "react"
import DarkModeIsActive from "../../functions/DarkModeIsActive"
import "./StaticImage.scss"

function StaticImage({ uri, uriDarkMode, urlDarkMode, url, alt, className, asBackground, width, height, children, ariaLabel }) {

    let src = url ? url : process.env.PUBLIC_URL + "/assets/image" + uri


    if (DarkModeIsActive() && (uriDarkMode || urlDarkMode)) {
        src = urlDarkMode ? (urlDarkMode) : process.env.PUBLIC_URL + "/assets/image" + (uriDarkMode || uri)
    }

    return (
        <div className={"StaticImage " + className}>
            {asBackground ?
                <div
                    aria-label={ariaLabel || alt}
                    className="asBackground"
                    style={{ backgroundImage: `url(${src})`, height: height, width: width }} children={children}
                />
                :
                <img alt={alt || "Bild"} aria-label={ariaLabel || alt} src={src} />
            }
        </div>
    )
}
export default StaticImage