import React from "react"
import { Route, Routes } from "react-router-dom"
import HeaderCook from "../../components/menus/Header/HeaderCook/HeaderCook"
import HeaderMenu from "../../components/menus/Header/HeaderMenu/HeaderMenu"
import "./HeaderNavigation.scss"

function HeaderNavigation({ }) {
    return (
        <div className="HeaderNavigation">
            <Routes>
                <Route path="/rezepte/:slug/kochen/*" element={<HeaderCook />} />
                <Route path={"*"} element={<HeaderMenu />} />
            </Routes>
        </div>
    )
}
export default HeaderNavigation