import React from "react"
import BorderRadiusBox from "../../components/default/boxes/BorderRadiusBox/BorderRadiusBox"
import TextPage from "../../components/default/pages/TextPage"
import GhostLoader_Article from "../../components/loader/GhostLoader_Article"
import { useApi } from "../../services/useApi"
import "./AblaufplanPage.scss"

function AblaufplanPage({ }) {

    let { Data, Loading, Error } = useApi("/ablaufplan?populate=deep")

    if (Error) return <p>Fehler beim laden.</p> //todo: Error Page
    if (Loading) return <GhostLoader_Article />

    let { title, items, updatedAt } = Data?.data.attributes || { items: [] }


    //Items sortieren
    items.sort((a, b) => {
        let dateA = new Date(a.date);
        let dateB = new Date(b.date);
        return dateA - dateB;
    });

    //Alte Items aussortieren
    let oldItems = []
    let futureItems = []
    let currentDate = new Date()
    items.forEach(item => {
        if (new Date(item.date) > currentDate) {
            futureItems.push(item)
        } else {

            oldItems = [item, ...oldItems,]
        }
    });




    let lastUpdate = new Date(updatedAt)
    return (
        <TextPage title={title || "Ablaufplan (mög. nicht aktuell)"} className="AblaufplanPage">
            <small>Zuletzt aktualisiert am {lastUpdate.toLocaleDateString() + " um " + lastUpdate.toLocaleTimeString()} Uhr</small>
            <hr />


            <div className="legende mb-5">
                {types.map((i, k) => <LegendeItem {...i} key={k} />)}
            </div>

            <div className="mb-5">
                <h2>Zukünftige Termine:</h2>
                {futureItems.map((i, k) => <AblaufplanEntry {...i} key={k} />)}
            </div>

            <div className="mb-5" style={{ opacity: 0.5 }}>
                <h2>Vergangene Termine:</h2>
                <div>
                    {oldItems.map((i, k) => <AblaufplanEntry {...i} key={k} />)}
                    {/* <button>Öffnen</button> */}
                </div>
            </div>
        </TextPage>
    )
}
export default AblaufplanPage

function LegendeItem({ typeTitle, color }) {
    return <div className="LegendeItem">
        <div className="dot" style={{ background: color }} />
        <span>{typeTitle}</span>
    </div>
}

function AblaufplanEntry({ title, subTitle, type, date, magazin, id }) {


    let typeData = types.filter(t => t.key == type)[0]
    let { color, typeTitle } = typeData


    let { inText, dateText } = formatDate(date)


    return (<BorderRadiusBox className="AblaufplanEntry p-4 ">

        <div className="d-flex flex-row">
            <div className="bar" style={{ background: color }} title={typeTitle} />
            <div>
                <h3><b>{dateText}</b><br />{title}</h3>
                <p><small><b>{inText}</b></small></p>
                <p>{subTitle}</p>
                <small>Kategorie: {typeTitle}</small><br />
                <small>Magazin: {magazin.data.attributes.numberString}</small>
            </div>
        </div>
    </BorderRadiusBox>)
}

const types = [

    {
        typeTitle: "Anzeigenschaltungs-Schluss",
        key: "ANZEIGENSCHALTUNG_SCHLUSS",
        color: "var(--sub)",
    },
    {
        typeTitle: "Anzeigenlegung",
        key: "ANZEIGENLEGUNG",
        color: "var(--subDarker)",
    },
    {
        typeTitle: "Redakteure",
        key: "REDAKTEURE",
        color: "blue",
    },
    {
        typeTitle: "Druckerei",
        key: "DRUCKEREI",
        color: "grey",
    },
    {
        typeTitle: "Lettershop",
        key: "LETTERSHOP",
        color: "var(--green)",
    },
    {
        typeTitle: "Aufgabe dashdesign;",
        key: "dashdesign",
        color: "yellow",
    },
    {
        typeTitle: "Sonstiger Termin",
        key: null,
        color: "red",
    },
]

function stringToDate(dateString) {
    return dateString
    // return new Date(dateString);
}


function formatDate(dateString) {
    let date = new Date(dateString);
    let currentDate = new Date();
    let difference = date - currentDate;
    let oneDay = 24 * 60 * 60 * 1000;
    let oneHour = 60 * 60 * 1000;
    let months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    let day = date.getDate();
    let month = months[date.getMonth()];
    let year = date.getFullYear();
    let showYear = year !== currentDate.getFullYear();

    let inText = ""

    if (difference < oneDay && difference > 0) {
        let hours = Math.floor(difference / oneHour);
        inText = `In ${hours} Stunden`;
    } else if (difference < 0) {
        inText = `Vor ${Math.ceil(-difference / oneDay)} Tagen`;
    } else {
        inText = `In ${Math.floor(difference / oneDay)} Tagen`;
    }

    return ({
        inText: inText,
        dateText: `${day}. ${month}${showYear ? ` ${year}` : ""}`
    })
}
