import React from "react"
import "./HtmlRenderer.scss"

function HTML({ children }) {
    let htmlCode = children

    return (
        <div className="HtmlRenderer" dangerouslySetInnerHTML={{ __html: htmlCode }} />
    )
}
export default HTML