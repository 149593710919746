import React, { useEffect, useState } from "react";
import Url from "../../../components/default/buttons/Url/Url";
import "./GeneralSearchPage.scss";



function GeneralSearchPage({ search = "" }) {
    const [hits, setHits] = useState([]);

    useEffect(() => {
        const searchResults = fixedPages.filter(page => {
            if (page.title?.includes(search)) {
                return true;
            }
            if (Array.isArray(page.keywords)) {
                return page.keywords.some(keyword => keyword?.includes(search));
            }
            return false;
        });

        setHits(searchResults);
    }, [search]);

    if (!hits || hits.length === 0) {
        return <div></div>;
    }

    return (
        <div className="GeneralSearchPage">
            {/* Woraround damit die Komponente komplett neu rendert */}
            <div className="d-none">{search}</div>

            {hits.map((page, index) => (
                <Url to={page.uri || page.url} key={index} className="item">
                    {page.icon ? (
                        page.icon
                    ) : (
                        <i className="fa-duotone fa-file-lines"></i>
                    )}
                    <div className="cont">
                        <span className="title">{page.title || " "}</span>
                        <span className="subTitle">
                            {page.subTitle}
                            {page.external ? (
                                <i className="fa-solid fa-arrow-up-right-from-square"></i>
                            ) : <i className=""></i>}
                            {/* Sonst fliegt hier ein Fehler */}
                        </span>
                    </div>
                </Url>
            ))}
        </div>
    );
}

export default GeneralSearchPage;


const fixedPages = [
    {
        icon: <i className="fa-duotone fa-file-pdf"></i>,
        title: "Mediadaten",
        subTitle: "Informationen für Werbekunden",
        keywords: ["werbung", "design",],
        uri: "/mediadaten",
    },


    {
        icon: <i className="fa-duotone fa-address-card"></i>,
        title: "Kontakt-Seite",
        subTitle: "",
        keywords: ["redaktion", "sillich", "bernhard"],
        uri: "/kontakt",
    },
    {
        icon: <i className="fa-duotone fa-kitchen-set"></i>,
        title: "Alle Rezepte",
        subTitle: "Zahlreiche Rezepte,",
        keywords: ["kochen", "rezepte",],
        uri: "/rezepte",
    },
    {
        icon: <i className="fa-duotone fa-circle-dashed"></i>,
        title: "Basisprogramm-Seite",
        subTitle: "Entdecken Sie Dr. Mohr's Basisprogramm",
        keywords: ["Basisprogramm"],
        uri: "/basisprogramm"
    },
    {
        icon: <i className="fa-duotone fa-book-open"></i>,
        title: "Online Magazin",
        keywords: ["übersicht"],
        subTitle: "Top Gesundheitstrends online lesen",
        uri: "/"
    },
    {
        title: "Magazin Shop",
        icon: <i className="fa-duotone fa-bag-shopping"></i>,
        keywords: ["kaufen", "bestellen", "buy", "abo", "print"],
        subTitle: "Bestellen Sie oder abonnieren unser Magazin",
        external: true,
        url: "https://shop.reformleben.de",
    },
]