export function FormartToEuro(amount) {
    // Rundet die Zahl auf 2 Nachkommastellen
    amount = Math.round(parseFloat(amount) * 100) / 100;

    // Konvertiert die Zahl in einen String und fügt fehlende Nullen hinzu
    var euroString = amount.toFixed(2);

    // Tauscht das Dezimaltrennzeichen "." gegen ","
    euroString = euroString.replace('.', ',');

    // Fügt das Währungssymbol "€" hinzu
    euroString = euroString + ' €';

    // Gibt das Ergebnis zurück
    return euroString;
}
