import React, { useState } from "react"
import "./CounterInput.scss"

const DEFAULT_MIN_COUNT = 1
const DEFAULT_MAX_COUNT = 99

function CounterInput({ value, onChange, minCount = DEFAULT_MIN_COUNT, maxCount = DEFAULT_MAX_COUNT }) {

    const [Amount, setSetAmount] = useState(2)

    function handleOnChange(e) {
        onChange(e)
    }

    function setAmount(value) {

        if (value <= minCount) value = minCount
        if (value > maxCount) value = maxCount


        handleOnChange({ target: { value: value } })
        setSetAmount(Number(value))
    }

    const handleSetValue = (e) => setAmount(e.target.value)
    const handleMinus = () => setAmount(Amount - 1)
    const handlePlus = () => setAmount(Amount + 1)

    const isMinCount = minCount == Amount

    return (
        <div className="CounterInput">
            <button className="minus" style={{ opacity: isMinCount ? 0.25 : 1 }} onClick={handleMinus}>
                <i className="fa-solid fa-minus-circle" />
            </button>
            <input max={99} min={1} value={Amount} type="number" onChange={handleSetValue} />
            <button className="plus" onClick={handlePlus}>
                <i className="fa-solid fa-plus-circle" />
            </button>
        </div>
    )
}
export default CounterInput