import React from "react"
import { Link } from "react-router-dom"
import { useApi } from "../../../../services/useApi"
import "./CategorySelection.scss"

function CategorySelection({ className }) {

    let { Data = { data: [] }, Loading, Error } = useApi("/categories")

    if (Loading) return <p>Lädt</p>
    if (Error) return <p>Fehler</p>
    return (
        <div className={"CategorySelection " + className}>
            <div className="d-flex flex-row flex-wrap">
                {Data?.data.map((i, k) => <CategoryButton  {...i?.attributes} key={k} />)}
            </div>
        </div>
    )
}
export default CategorySelection

function CategoryButton(props) {
    let { title, borderColors, className, bg1, ids, bg2, textColor, backgroundImage, uri, onClick, back, icon } = props

    function handleClick(e) {
        if (onClick) {
            e.stopPropagation(); //für später, wenn div ein link ist
            onClick(props)
        }
    }

    {/* todo: uri */ }
    return (

        <Link
            to={"/kategorie/" + uri}
            onClick={handleClick}
            className={"CategoryButton " + className}
            style={{
                background: `linear-gradient(12deg,${bg1},${bg2})`,
            }}
        >
            <h3 style={{ color: textColor }}>
                {icon && <i className={"fa-solid fa-" + icon} style={{ color: textColor }} />}
                {title}
            </h3>

        </Link>
    )
}

const items = [
    {
        title: "Keto",
        borderColors: ["", ""],
        bg1: "#ed9015",
        bg2: "#cc144d",
        backgroundImage: "",
        textColor: "#fff",
        uri: "/keto",
        ids: [999],
        icon: "egg-fried",
    },
    {
        title: "Basisprogramm",
        borderColors: ["", ""],
        bg1: "#e30075",
        bg2: "#e30075",
        backgroundImage: "",
        textColor: "#fff",
        uri: "/basisprogramm",
        ids: [51,],
        icon: "circle",
    },

    {
        title: "Essen & trinken",
        borderColors: ["", ""],
        bg1: "#242021",
        bg2: "#3d342f",
        backgroundImage: "",
        textColor: "#fff",
        uri: "/basisprogramm",
        ids: [10],
        icon: "utensils",
    },
    {
        title: "Fitness",
        borderColors: ["", ""],
        bg1: "#6c1ea8",
        bg2: "#976be8",
        backgroundImage: "",
        textColor: "#fff",
        uri: "/basisprogramm",
        ids: [5],
        icon: "baseball",
    },
    {
        title: "Lifestyle",
        borderColors: ["", ""],
        bg1: "#1c0ee6",
        bg2: "#63e6c9",
        backgroundImage: "",
        textColor: "#fff",
        uri: "/basisprogramm",
        ids: [12],
        icon: "sun-haze",
    },
    {
        title: "Natur",
        borderColors: ["", ""],
        bg1: "#0f5132",
        bg2: "#28b085",
        backgroundImage: "",
        textColor: "#fff",
        uri: "/basisprogramm",
        ids: [79],
        icon: "tree",
    },
    {
        title: "Medizin",
        borderColors: ["", ""],
        bg1: "#8a0404",
        bg2: "#d61818",
        backgroundImage: "",
        textColor: "#fff",
        uri: "/basisprogramm",
        ids: [79],
        icon: "stethoscope"
    },
    {
        title: "Beauty",
        borderColors: ["", ""],
        bg1: "#fac3d2",
        bg2: "#faf0f3",
        backgroundImage: "",
        textColor: "#111",
        uri: "/basisprogramm",
        ids: [154],
        icon: "pen-fancy",

    },
]