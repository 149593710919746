import React from "react"
import { Link } from "react-router-dom"

function CategoryText({ category }) {

    if (!category) return null
    if (category.data) category = category.data
    if (category.attributes) category = category.attributes

    let { title, icon, uri, bg1, bg2, } = category || {}

    return (
        <div className="CategoryText">
            <Link to={"/kategorie/" + uri}>
                {title}
            </Link>
        </div>
    )
}
export default CategoryText