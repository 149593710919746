import { Adsense } from '@ctrl/react-adsense';
import React from 'react';
import "./AdBanner.scss";


function AdBanner(props) {

    let { layoutKey, format, slot, height } = props

    // useEffect(() => {
    //     try {
    //         (window.adsbygoogle = window.adsbygoogle || []).push({});
    //     }
    //     catch (e) {
    //         console.log(e)
    //     }
    // }, []);

    // if (hasValidAccessCode()) {
    //     console.log("Keine Werbung danke reformleben+")
    //     return null
    // }

    // console.log(props)

    return (

        <div className='AdBanner'>
            {/* <Adsense
                client="ca-pub-7640562161899788"
                slot="7259870550"
            /> */}

            {/* // ads with custom format
            <Adsense
                client="ca-pub-7640562161899788"
                slot="7259870550"
                style={{ width: 500, height: 300 }}
                format=""
            /> */}

            {/* // responsive and native ads */}
            <Adsense
                client="ca-pub-5717017486576175"
                slot={slot}
                style={{ display: 'block' }}
                layout="in-article"
                height={height}
                format={format}
            />


        </div>
    );
};
export default AdBanner

//Ads ID: ca-pub-5717017486576175