import React from "react"
import { Link } from "react-router-dom"
import { useAuthor } from "../../../services/author/useAuthorHook"
import ProfileImage from "../../images/ProfileImage/ProfileImage"
import GhostLoader from "../../loader/GhostLoader"
import "./AuthorBox.scss"


function AuthorBox({ authorID, dateString = "", noImage, textClass = "" }) {

    let { Data, Error, Loading } = useAuthor(authorID);
    if (Loading) return <GhostLoader width={128} height={12} borderRadius={4} />
    if (Error) return <div className="AuthorBox"><span className="name">KA.</span></div>
    if (Data) {

        let authorData = Data
        let { fullName, profileImage, slug, initiales, slogan, wordpressID } = authorData

        return (
            <Link to={"/author/" + wordpressID + "/" + slug} className="AuthorBox">
                <>
                    {!noImage && <ProfileImage
                        strapiImageObj={profileImage}
                        fullName={fullName}
                        size={40}
                    />}
                    <div className={textClass}>
                        <span className="name pe-1">{fullName} </span>
                        <span className="date">{dateString} </span>
                    </div>
                </>
            </Link>
        )
    }
}
export default AuthorBox