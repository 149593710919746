import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useRecipe } from "../../../services/recipes/useRecipe";
import "./RezeptSinglePage.scss";
import RezeptPageInner from "./RezeptSinglePageInner";

function RezeptSinglePage({ }) {
    let location = useLocation()
    let { attributes } = location?.state || {}
    let { slug } = useParams();

    if (attributes) return <RezeptPageInner data={attributes || {}} />

    const F = () => {
        let { Data, Loading, Error } = useRecipe(slug)
        console.log("Data")
        console.log(Data)
        if (Loading) return <p>Lädt...</p>
        if (Error) return <p>Error: {Error}</p>
        if (Data) {
            return <RezeptPageInner data={Data?.data?.data[0].attributes || {}} />
        }
    }
    return <F />
}
export default RezeptSinglePage
