import React, { useEffect } from "react"
import BookBox from "../../components/box/BookBox/BookBox"
import TextPage from "../../components/default/pages/TextPage"
import ErrorText from "../../components/loader/ErrorText"
import GhostLoader from "../../components/loader/GhostLoader"
import { useApi } from "../../services/useApi"
import "./BookListPage.scss"

function BookListPage({ }) {

    // const [Data, setData] = useState()
    // const [Error, setError] = useState(false)
    // const [Loading, setLoading] = useState(true)

    let { Data, Loading, Error } = useApi("/books?populate=*")

    useEffect(() => {

    }, [])

    if (Loading) return <GhostLoader />
    if (Error) return <ErrorText />
    if (Data) {

        return (
            <TextPage title="Buchempfehlungen" className="BookListPage">

                {/* <p>Hier muss noch ein Text hin.</p>

                <h2>Aktuelle Buchempfehlungen</h2> */}
                {/* <h2>Hier finden Sie Buchtipps von unserer Redaktion</h2> */}
                <div className="row">
                    {Data.data.map((i, k) => <div className="col-12 col-lg-6 col-xl-4 mb-2"><BookBox {...i.attributes} key={k} /></div>)}
                </div>

            </TextPage>
        )
    }
}
export default BookListPage