import axios from "axios";

export function alenaxios(request) {
  if (process.env.NODE_ENV === 'development') {
    return axios(request);
  }

  let cacheKey = request.url;
  let cacheDurationInMinutes = request.cacheDurationInMinutes || 60
  let cachedResponse = localStorage.getItem(cacheKey);

  if (!request.method) request.method = "GET"

  if (cachedResponse) {
    let cacheTimestamp = localStorage.getItem(cacheKey + ':t');
    let age = Date.now() / 1000 - cacheTimestamp;
    let cacheDurationInMilliseconds = cacheDurationInMinutes * 60000;

    if (age < cacheDurationInMilliseconds) {
      // console.log("Use local axios cache " + cacheKey)
      return Promise.resolve({ data: JSON.parse(cachedResponse) });
    } else {
      // console.log("Use axios  " + cacheKey)
      localStorage.removeItem(cacheKey);
      localStorage.removeItem(cacheKey + ':t');
    }
  }

  return axios(request)
    .then(response => {
      let responseToCache = JSON.stringify(response.data);
      localStorage.setItem(cacheKey, responseToCache);
      localStorage.setItem(cacheKey + ':t', Date.now() / 1000);
      return response;
    });
}


export function alenaxiosGet(url, cacheDurationInMinutes = 60) {
  return alenaxios({
    method: "GET",
    url: url,
    cacheDurationInMinutes: cacheDurationInMinutes
  })
}

const CurrentDate = Date.now() / 1000;

function getStore(storageName) {
  let storeData = localStorage.getItem(storageName)

  let { data, ttl, } = JSON.parse(storeData || "{}")

  if (ttl && data && ttl > CurrentDate) {

    return data
  }
  return false
}


async function store(storageName, ttl, data) {


  let storeString = JSON.stringify({
    ttl: CurrentDate + (ttl * 1000),
    data: data,
  });

  localStorage.setItem(storageName, storeString);
}
