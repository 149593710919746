import React from "react"
import StaticImage from "../../images/StaticImage"
import "./ColoredBox.scss"

function ColoredBox({ title, suffix, image, items = [], subTitle }) {
    return (
        <div className="ColoredBox p-5">
            <div className="container p-5 px-0">
                <div className="row align-items-center mb-5">
                    <div className="col-12 col-md-7">
                        <div className="pb-4">
                            <div className="mb-5">
                                <h2>{title}</h2>
                                {subTitle && <span className="subTitle">{subTitle}</span>}
                                <hr />
                            </div>
                            {items.map((i, k) =>
                                <React.Fragment key={k}>
                                    <h3>{i.title}</h3>
                                    <p>{i.text}</p>
                                </React.Fragment>
                            )}
                            <div className="col-12">
                                {suffix}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-5 pt-5">
                        {image ?
                            <StaticImage  {...image} />
                            : null}
                    </div>
                </div>
                <div className="row">

                </div>
            </div>
        </div>
    )
}
export default ColoredBox