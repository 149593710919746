import React from "react"
import { Link } from "react-router-dom"
import HTML from "../HtmlRenderer/HtmlRenderer"
import "./Breadcrumps.scss"

let defaultPath = [
    { title: "Unterseite", uri: "/" }
]

function Breadcrumps({ path = defaultPath }) {

    path = [{ title: "Home", uri: "/" }, ...path]

    return (
        <div className="Breadcrumps d-print-none">
            <div className="paths">
                {
                    path.map((i, k) =>
                        <Link to={i.uri} key={k} className="pathItem">
                            <span><HTML>{i.title}</HTML></span>
                            <i className="fa-regular fa-chevron-right" />
                        </Link>
                    )
                }
            </div>
        </div>
    )
}
export default Breadcrumps