import React from "react"
import { Link } from "react-router-dom"
import TextPage from "../../../components/default/pages/TextPage"
import GhostLoader from "../../../components/loader/GhostLoader"
import { useApi } from "../../../services/useApi"
import "./PageRedirectOr404.scss"

function PageRedirectOr404({ }) {
    let uri = window.location.pathname.replace(/^\/+/g, ''); //encodeURI(??
    let { Data, Loading, Error } = useApi("/redirect-links?filters[uri][$eq]=" + uri, false, 0)

    console.log(uri)
    console.log(Data)

    if (Data?.data) Data = Data.data

    if (Loading) return <GhostLoader />
    if (Error || !Array.isArray(Data) || Data.length == 0 || !Data[0].attributes) return <Page404 />


    let { targetUrl, title, description, autoRedirect, buttonText } = Data[0].attributes


    function handleRedirect() {
        window.location.replace(targetUrl);
    }


    if (autoRedirect) {
        handleRedirect()
        return (
            <TextPage title="Automatische Weiterleitung ...">
                <p>Sie werden zu <a href={targetUrl}>{targetUrl}</a> weitergeleitet...</p>
            </TextPage>
        )
    } else {
        return (
            <TextPage
                className="RedirectPage"
                title={title || "Weiterleitung"}
                hideBreadcrumbs
                breadcrumpsProps={{
                    path: [
                        {
                            title: "Weiterleitung",
                            to: "/"
                        },
                    ]
                }}>
                <p>{description || `Möchten Sie zu '${targetUrl}' weitergeleitet werden?`}</p>
                <div className="d-flex justify-content-end">
                    <button onClick={handleRedirect}>
                        {buttonText || "Weiter"} <i className="fa-solid fa-angle-right"></i>
                    </button>
                </div>
            </TextPage >
        )
    }
}
export default PageRedirectOr404

export function Page404({ }) {
    let uri = window.location.pathname
    return (
        <div className="Page Page404">
            <div style={{ maxWidth: 900 }} className="container">
                <span>
                    <i className="fa-solid fa-face-rolling-eyes"></i>
                </span>
                <h1>Seite nicht gefunden.</h1>
                <span style={{ opacity: 0.5 }}>#403/404</span>
                <hr></hr>
                <p>Es tut uns Leid, aber die gesuchte Seite "<b>{uri}</b>" wurde nicht gefunden. <br />Diese Seite existiert nicht oder wurde gelöscht. </p>
                <hr></hr>
                <Link to="/">
                    <button>Zur Startseite</button>
                </Link>
            </div>
        </div>
    )
}