export function formartDuration(durationInMinutes, defaultFallback = "Keine Zeitangabe") {
    let strResult = defaultFallback
    let h = 0
    let m = durationInMinutes || 0

    while (m >= 60) {
        h++;
        m -= 60;
    }

    // if (m > 0) {
    //     strResult = m + " Min."
    // }
    // if (h > 0) {
    //     strResult = h + "Std. " + strResult
    // }

    if (m <= 9) {
        m = m + "0"
    }

    if (!h) {
        if (!m || m == "00") {
            return "Keine Angabe"
        }
        return m + " min."
    }

    return `${h}:${m} Std.`
}