import React from "react"
import { Link } from "react-router-dom"
import StrapiImage from "../../default/strapi/StrapiImage/StrapiImage"
import "./MagazinBox.scss"

function MagazinBox({ numberString, shopUrl, releaseDate, countNumber, cover }) {
    return (
        <Link to={"/magazin/ausgabe/" + countNumber}>
            <div className="MagazinBox">

                <StrapiImage image={cover} />

                <div>
                    <span className="countNumber">Nr. {countNumber}</span>
                    <span className="numberString">Ausgabe {numberString}</span>
                </div>

            </div>
        </Link>
    )
}
export default MagazinBox