import React, { useRef, useState } from "react"
import "./SearchInput.scss"

function SearchInput({ value, onSearch, isOpen, onClose, searchText = "Suchbegriff eingeben" }) {

    const inputBinding = useRef()
    const [ShowInput, setShowInput] = useState(false)
    const [IsOpen, setIsOpen] = useState(isOpen)
    const [Value, setValue] = useState(value)

    // const handleFocus = () => {
    //     onSearch("")
    // }

    const handleButtonPress = () => {
        if (IsOpen) {
            onClose()
            setValue("")
        } else {
            inputBinding.current.focus()
            setIsOpen(true)
        }
    }
    return (
        <div className={"SearchInput " + (IsOpen ? "isOpen" : null)}>
            <div className="input-group mb-3">
                {
                    true ?
                        <input
                            ref={inputBinding}
                            type="text"
                            className="col"
                            placeholder={searchText}
                            aria-label={searchText}
                            value={value}
                            onChange={(e) => onSearch(e.target?.value || "")}
                        />
                        : null}

                <button
                    // onFocus={handleFocus}
                    onClick={handleButtonPress}
                    className="btn btn-outline-secondary"
                    type="button"
                    aria-label="Suchen"
                >
                    <i className="fa-solid fa-search" />
                    {/* {
                        IsOpen ?
                            <i className="fa-solid fa-xmark" />
                            :
                            <i className="fa-solid fa-search" />

                    } */}
                </button>

            </div>
        </div>
    )
}
export default SearchInput