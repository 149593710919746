import searchArticle from "./searchArticle";
import searchPage from "./searchPage";

export default async function GlobalSearch(searchText) {


    const article = await searchArticle(searchText)
    const pages = await searchPage(searchText)



    const results = {
        hits: article.length + pages.length,
        categories: [
            {
                icon: "newspaper",
                title: "Neuigkeiten",
                items: article,
                render: (i) => <p>{JSON.stringify(i)}</p>,
            },
            {
                icon: "file",
                title: "Seiten",
                items: pages,
                render: (i) => <p>{JSON.stringify(i)}</p>,
            },


            {
                icon: "newspaper",
                title: "Rezepte",
                items: [],
                render: (i) => <p>{JSON.stringify(i)}</p>,
            },
            {
                icon: "user",
                title: "Autoren",
                items: [],
                render: (i) => <p>{JSON.stringify(i)}</p>,
            },

            {
                icon: "book",
                title: "Bücher",
                items: [],
                render: (i) => <p>{JSON.stringify(i)}</p>,
            },
            {
                icon: "book-open",
                title: "Ausgaben",
                items: [],
                render: (i) => <p>{JSON.stringify(i)}</p>,
            },


            {
                icon: "newspaper",
                title: "Events",
                items: [],
                render: (i) => <p>{JSON.stringify(i)}</p>,
            },
        ],
    };

    return results;
}