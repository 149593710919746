import React from "react"
import BorderRadiusBox from "../../../default/boxes/BorderRadiusBox/BorderRadiusBox"
import GhostLoader from "../../GhostLoader"
import GhostLoader_Text from "../../GhostLoader_Text"
import GhostLoader_TrendingBox from "../GhostLoader_TrendingBox/GhostLoader_TrendingBox"
import "./GhostLoader_Sidebar.scss"

function GhostLoader_Sidebar({ }) {
    return (
        <div className="GhostLoader_Sidebar sidebar">
            <BorderRadiusBox className="p-3">
                <GhostLoader className={"round"} height={46} width={46} borderRadius={50} />
                <GhostLoader_Text height={22} words={1} />
                <GhostLoader_TrendingBox />
            </BorderRadiusBox>
            <BorderRadiusBox className="p-3">
                <div className="mb-2">
                    <GhostLoader className={"round"} height={46} width={46} borderRadius={50} />
                    <GhostLoader_Text height={22} words={3} />
                    <GhostLoader_Text height={12} words={7} />
                </div>
                <div style={{ opacity: 0.5, marginBottom: 4, }}>
                    <GhostLoader height={32} borderRadius={12} />
                </div>
                <GhostLoader height={32} borderRadius={12} />
            </BorderRadiusBox>
            <BorderRadiusBox className="p-3">
                <div className="mb-2">
                    <GhostLoader className={"round"} height={46} width={46} borderRadius={50} />
                    <GhostLoader_Text height={22} words={3} />
                    <GhostLoader_Text height={12} words={6} />
                </div>
                <GhostLoader height={38} borderRadius={12} />
            </BorderRadiusBox>
        </div>
    )
}
export default GhostLoader_Sidebar