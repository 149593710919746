import React, { useState } from "react"
import { Helmet } from "react-helmet"
import RezepteKcal from "../../../components/box/rezepte/RezepteKcal/RezepteKcal"
import RezeptNutritionalValues from "../../../components/box/rezepte/RezeptNutritionalValues/RezeptNutritionalValues"
import RezeptScore from "../../../components/box/rezepte/RezeptScore/RezeptScore"
import ShoppingList from "../../../components/box/rezepte/ShoppingList/ShoppingList"
import BorderRadiusBox from "../../../components/default/boxes/BorderRadiusBox/BorderRadiusBox"
import CookButton from "../../../components/default/buttons/CookButton/CookButton"
import CounterInput from "../../../components/default/inputs/CounterInput/CounterInput"
import Breadcrumps from "../../../components/default/more/Breadcrumps/Breadcrumps"
import TextPage from "../../../components/default/pages/TextPage"
import MediaCarousel from "../../../components/default/strapi/MediaCarousel"
import "./RezeptSinglePage.scss"

function RezeptSinglePageInner({ data }) {
    const [Amount, setAmount] = useState(data?.portions || 4)

    if (data) {
        let { portions, healthBecauseOf, ingredients, tipText, nutritionalValues, kcal, slug, categories, title, images, steps, description } = data || {}

        let cookUrl = "/rezepte/" + slug + "/kochen"
        let breadcrumpsProps = {
            path: [
                {
                    title: "Rezepte",
                    uri: "/rezepte/"
                },
                {
                    title: data.title,
                },
            ]
        }

        function handleAmountChange(e) {
            setAmount(e.target.value)
        }

        const imageUrl = images?.[0]?.attributes?.url;
        return (
            <TextPage
                metaTitle={title + " | Rezept"}
                metaDescription={`Gesundes und lecker: ${title}: ${kcal} kcal – ${tipText} `}
                // title={data.title}
                className="RezeptPage"
                hideBreadcrumbs

            // headerImage={data.image}
            // useBottomTitle
            >
                <Helmet>
                    <script type="application/ld+json">{`
                        {
                            "@context": "http://schema.org",
                            "@type": "Recipe",
                            "name": "${title}",
                            "image": "https://reformleben.de${imageUrl}",
                            "description": "${description}",
                            "recipeIngredient": [
                            ${ingredients.map(ingredient => `"${ingredient.Menge} ${ingredient.Unit || "x"} ${ingredient.Title}"`).join(',\n')}
                            ],
                            "recipeInstructions": "${steps}",
                            "nutrition": {
                            "@type": "NutritionInformation",
                            "calories": "${kcal}kcal",
                            "servingSize": "1"
                            },
                        
                            "suitableForDiet": {
                            "@type": "RestrictedDiet",
                            "name": "${healthBecauseOf}"
                            }
                        }
                    `}</script>
                    {/* // "recipeCategory": "${categories.join(', ')}", */}


                </Helmet>

                <div className="">
                    <div className="d-flex flex-row justify-content-between ">

                        <div>
                            <Breadcrumps {...breadcrumpsProps} />

                            <h1>{title}</h1>
                            <div className="icons d-flex flex-row align-items-left">
                                {categories?.map((i, k) =>
                                    <div
                                        key={k}
                                        title=""
                                        className="icon d-flex flex-row align-items-center"
                                    >
                                        <i className={`fa-solid fa-${i.icon}`} />
                                        <span>{i.title}</span>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="d-none d-md-block px-0 px-lg-5">
                            <CookButton cookUrl={cookUrl} />
                        </div>
                    </div>
                </div>

                <hr className="mb-4 mt-0" />

                <div className="container">
                    <div className="row">
                        {/* Hauptteil */}
                        <BorderRadiusBox className="col-12 col-md-7">
                            <MediaCarousel data={images?.data || []} alt={title} />
                            <div className="p-2">
                                {data.description ?
                                    <div className="mb-5">
                                        <h2>Beschreibung:</h2>
                                        <p>{data.description || "Keine Beschreibung"}</p>
                                    </div>
                                    : null}

                                <div className="mb-5">
                                    <h2>Anleitung:</h2>
                                    <p>{data.steps || "Keine Anleitung verfügbar."}</p>

                                    {tipText ?
                                        <div className="pt-4">
                                            <h3>Tipp der Redaktion:</h3>
                                            <p>{tipText}</p>
                                        </div>
                                        : null}
                                </div>
                                {
                                    data.healthBecauseOf ?
                                        <div className="mb-5">
                                            <h2>Gesund weil:</h2>
                                            <p>{data.healthBecauseOf}</p>
                                        </div>
                                        : null
                                }
                            </div>
                        </BorderRadiusBox>

                        {/* Sidebar */}
                        <div className="col-12 col-md-5 ">
                            <div>
                                <ShoppingList
                                    ingredients={ingredients}
                                    amount={Amount}
                                    header={
                                        <>
                                            <h2>Zutaten:</h2>
                                            <span className="subHeading">
                                                <CounterInput value={Amount} onChange={handleAmountChange} />
                                                <span>Portionen</span>
                                            </span>
                                        </>
                                    }
                                    footer={
                                        <div className="d-flex flex-row d-print-none">
                                            <button onClick={handlePrint}>
                                                <i className="fa-solid fa-print"></i>
                                            </button>
                                            <button>
                                                <i className="fa-regular fa-arrow-up-from-bracket"></i> Teilen
                                            </button>
                                            <CookButton cookUrl={cookUrl} />
                                        </div>
                                    }
                                />
                                <RezeptNutritionalValues  {...nutritionalValues} />
                                <RezeptScore score={data.score} healthBecauseOf={healthBecauseOf} />
                                <RezepteKcal kcal={kcal} />
                            </div>
                        </div>
                    </div>
                </div>
            </TextPage>
        )
    }
    return <p>Leeres Rezept</p>
}
export default RezeptSinglePageInner

function handlePrint() {
    window.print();
}