import { useEffect, useState } from "react"
import { POST_TOOLTIPS_ENDPOINT } from "../../const/Endpoints"
import { STRAPI_API_KEY } from "../../const/Keys"
import { alenaxios } from "../../functions/alenaxios"

export function useNewsArticleTooltips(slug) {
    const [Data, setData] = useState(null)
    const [Error, setError] = useState(null)
    const [Loading, setLoading] = useState(false)


    useEffect(() => {
        (
            async function () {
                let endpoint = POST_TOOLTIPS_ENDPOINT + slug
                setError(false)
                setLoading(true)

                console.log("endpoint", endpoint)

                let config = {
                    method: 'get',
                    url: endpoint,
                    headers: {
                        'Authorization': `Bearer ${STRAPI_API_KEY}`
                    }
                };

                alenaxios(config)
                    .then(res => { setData(res.data?.data || {}) })
                    .catch((err) => { console.log(err); setError(true) })
                    .finally(() => setLoading(false))
            }
        )()
    }, [])

    return { Data, Loading, Error }
}