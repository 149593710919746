import React, { useEffect, useState } from "react"
import { SpeechString } from "../../../functions/Speech"
import "./RezeptCookPageStep.scss"

function RezeptCookPageStep({ }) {

    const [CurrentStep, setCurrentStep] = useState(0)

    function nextStep() {
        setCurrentStep(CurrentStep + 1)
    }



    useEffect(() => {
        let step = steps[CurrentStep]
        let text = step.title + " ... " + step.subTitle

        SpeechString(text)
    }, [CurrentStep])

    return (
        <div className="RezeptCookPageStep">
            <div className="container">
                <div className="text-center d-flex flex-column align-items-center">

                    {
                        steps.map((i, k) =>
                            <div
                                key={k}
                                className="mb-5"
                                style={{ opacity: k == CurrentStep ? 1 : 0.3, display: CurrentStep <= k ? "block" : "none" }}
                            >
                                <h2>{i.title}</h2>
                                <span><b>{i.subTitle}</b></span><br />
                                <span className="emoji">{i.emoji}</span><br />
                                <small>{i.desc}</small><br />
                            </div>
                        )
                    }





                </div>
            </div>
            <button onClick={nextStep} className="next">Nächster Schritt</button>
        </div >
    )
}
export default RezeptCookPageStep


const steps = [
    {
        title: "Los gehts...",
        emoji: "🔥",
    },
    {
        title: "Bringen Sie 375ml Wasser zum Kochen.",
        emoji: "🔥",
        subTitle: "... und warten Sie bis es gekocht hat.",
        desc: "Tipp: Nutzen Sie den Wasserkocher",
    },
    {
        title: "Rühren Sie 150ml Milch und das Püreepulver ein.",
        emoji: "🥛",
        subTitle: "Sie können den Herd schon ausschalten.",
        desc: "",
    },
    {
        title: "Würzen Sie den Kartoffelbrei mit einer Prise Salz.",
        emoji: "🧂",
        subTitle: "Tipp: Sie können den Brei verfeinern, indem Sie einen Esslöffel Butter einrühren.",
        desc: "",
    },
    {
        title: "Fertig!",
        emoji: "😋",
        subTitle: "Wir wünschen Ihnen einen guten Appetit!",
        desc: "",
    },
]

const stepsOld = [
    {
        title: "Heizen Sie Ihren Backofen vor",
        emoji: "🔥",
        subTitle: "180°C Umluft | 200°C Ober/Unterhitze",
        desc: "Wenn Sie Ihren Ofen jetzt schon vorheizen, sparen Sie später Zeit.",
    },
    {
        title: "Heizen Sie Ihren Backofen vor",
        emoji: "🔥",
        subTitle: "180°C Umluft oder 200°C Ober/Unterhitze",
        desc: "Wenn Sie Ihren Ofen jetzt schon vorheizen, sparen Sie später Zeit.",
    },
    {
        title: "Fügen Sie 500ml Milch hinzu.",
        emoji: "🥛",
        subTitle: "",
        desc: "",
    },
    {
        title: "Fügen Sie 200g Mehl hinzu.",
        emoji: "",
        subTitle: "Und Rühren Sie anschließen die Schüssel gut um.",
        desc: "",
    },
    {
        title: "Formen Sie die Brötchen",
        emoji: "🥯",
        subTitle: "",
        desc: "",
    },
    {
        title: "Fertig!",
        emoji: "😋",
        subTitle: "Wir wünschen Ihnen einen guten Appetit!",
        desc: "",
    },
]