import React from "react"
import TextPage from "../../../components/default/pages/TextPage"
import StaticImage from "../../../components/images/StaticImage"
import "./KontaktPage.scss"

function KontaktPage({ }) {
    return (
        <TextPage
            headerImage="/über/yoga_mit_reform-tshirt-am-see.jpg"
            className="KontaktPage"
            title="Ihr Kontakt in die Redaktion"
            useBottomTitle
            metaDescription={"Kontaktieren Sie unsere Redaktion"}
        >

            <div className="mb-5">


                <div className="row pt-5 pb-5 align-items-center">
                    <div className="col-12 col-md-7 pe-5">
                        {/* <h2>Ihr Kontakt in die Redaktion</h2> */}
                        <p className="welcome">Sie haben Fragen an uns, möchten mehr über unsere Redaktion erfahren oder uns einfach nur Ihre Meinung sagen?
                            Kontaktieren Sie uns, wir freuen uns über Feedback sind gerne für Sie da.</p>

                        <div className="d-flex flex-row p-3 ps-0">
                            <a href="mailto:info@reformleben.de" title="info@reformleben.de"><button className="main"> <i className="fa-solid fa-envelope"></i> Mail schreiben</button></a><br />
                            <a href="tel:4960829229000" title="(+49) 6082 9229000"><button> <i className="fa-solid fa-phone"></i> Jetzt anrufen</button></a><br />
                        </div>
                    </div>

                    <div className="col-12 col-md-5">
                        <StaticImage uri="/persons/BernhardSillich.jpg" />
                        <p className="text-center p-3">
                            Bernhard Sillich<br />
                            <small>Geschäftsführer</small>
                        </p>
                    </div>
                </div>

                <hr />

                <div className="pb-5">
                    <h2 style={{ fontSize: 28, }}>Anschrift</h2>
                    <p>
                        <b>zoe Media-Verlag GmbH</b>
                        <br />
                        Brunhildestr. 34<br />
                        61389 Schmitten<br />
                    </p>
                </div>

                <h2 style={{ fontSize: 28, }}>Kontaktdaten</h2>
                <p><b>zoe Media Verlag GmbH</b></p>
                <p>
                    T: <a href="tel:4960829229000">+49 6082 922900 – 0</a><br />
                    E: <a href="mailto:info@reformleben.de">info@reformleben.de</a><br /></p>

            </div >



        </TextPage >
    )
}
export default KontaktPage