import React from "react"
import GhostLoader_Text from "../../GhostLoader_Text"
import "./GhostLoader_TrendingBox.scss"

function GhostLoader_TrendingBox({ }) {
    return (
        <div className="GhostLoader_TrendingBox TrendingBox">
            <div className="trendingBoxItems">
                {/* 1 */}
                <div className="TrendingBoxItem d-flex flex-row">
                    <span className="number">1.</span>
                    <GhostLoader_Text words={3} />
                </div>

                {/* 2 */}
                <div className="TrendingBoxItem d-flex flex-row">
                    <span className="number">2.</span>
                    <GhostLoader_Text words={4} />
                </div>

                {/* 3 */}
                <div className="TrendingBoxItem d-flex flex-row">
                    <span className="number">3.</span>
                    <GhostLoader_Text words={4} />
                </div>
            </div>
        </div>
    )
}
export default GhostLoader_TrendingBox