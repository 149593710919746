import React from "react"
import BorderRadiusBox from "../../../default/boxes/BorderRadiusBox/BorderRadiusBox"
import "./RezepteKcal.scss"

function RezepteKcal({ kcal }) {
    return (
        <BorderRadiusBox className="RezepteKcal">
            {kcal ?
                <div className="p-4">
                    <h3 className="m-0">ca. {kcal} kcal </h3>
                    <p className="m-0">pro Portion</p>
                </div>
                :
                <p className="p-4">Kein Infos zu Kalorien verfügbar.</p>}
        </BorderRadiusBox>
    )
}
export default RezepteKcal