import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import MagazinBox from "../../components/box/MagazinBox/MagazinBox";
import AuthorBox from "../../components/box/news/AuthorBox";
import BorderRadiusBox from "../../components/default/boxes/BorderRadiusBox/BorderRadiusBox";
import StrapiImage from "../../components/default/strapi/StrapiImage/StrapiImage";
import { useApi } from "../../services/useApi";
import { Page404 } from "../more/PageRedirectOr404/PageRedirectOr404";
import "./BookPage.scss";

function BookPage({ }) {

    let { slug } = useParams();

    let { Data, Error, Loading } = useApi("/books/?populate[0]=magazins&populate[1]=magazins.cover&populate[2]=authors&populate[3]=coverImage&populate[4]=images&filters[slug][$eq]=" + slug)


    console.log(Error)

    if (Error) return <Page404 />
    if (Loading) return <p>Lädt...</p>
    if (Data) {

        console.log(Data)


        let { title, refLinks = [], description, price, images, coverImage, authors, slug, isbn13, magazins, authorText } = Data?.data[0]?.attributes || {}

        authors = authors?.data || []
        let authorsCount = authors?.length
        let haveMultipleAuthors = authorsCount > 1
        let authorTitle = haveMultipleAuthors ? "Autoren" : "Autor"

        console.log("magazins", magazins)

        return (
            <div className="BookPage">
                <Helmet>
                    {/* <script type="application/ld+json">
                        {
                            "@context": "https://schema.org",
                            "@type": "DataFeed",
                            "dataFeedElement": [
                                {
                                    "@context": "https://schema.org",
                                    "@type": "LibrarySystem",
                                    "@id": "https://example.com/library-systems/100",
                                    "name": "Santa Clara County Library District",
                                    "additionalProperty": [
                                        {
                                            "@type": "PropertyValue",
                                            "name": "librarytype",
                                            "value": "public"
                                        }
                                    ],
                                    ...
                                },
                                ...
                            ],
                            "dateModified": "2018-09-10T13:58:26.892Z"
                        }
                    </script> */}
                </Helmet>

                <div className="container mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-4">
                            <StrapiImage className={"w-100"} image={coverImage} />
                        </div>

                        <div className="col-12 col-lg-7 offset-lg-1 ">


                            <p>{authorText || "Autor nicht verfügbar"}</p>
                            <h1>{title}</h1>

                            <div className="d-flex flex-column py-5">
                                <span className="price">{price}€</span>
                                <small>inkl. MwSt.</small>
                            </div>

                            {refLinks.length == 0 ?
                                <div>
                                    <p className="red">Leider nicht verfügbar.</p>
                                </div>
                                :
                                <div>
                                    <small className="pb-2 d-block">Online verfügbar:</small>
                                    <div className="buttons p-0">
                                        {refLinks.map((i, k) =>
                                            <a target={"_blank"} key={k} href={i.url} rel="no-follow">
                                                <button className="main m-0 mr-2">{i.TYPE == "BUECHER.DE" ? "Bestellen auf bücher.de" : "Weitere Website"}</button>
                                            </a>
                                        )}
                                    </div>
                                </div>
                            }
                        </div>

                    </div>




                    <div className="pt-5 mt-4">
                        <div className="row">



                            <div className="col-12 col-lg-8">
                                <div>
                                    <h3>Beschreibung</h3>
                                    <p className="description">{description || "Keine Beschreibung verfügbar."}</p>
                                </div>

                            </div>


                            <div className="col-12 col-lg-4">
                                <BorderRadiusBox className="p-3 mb-3">
                                    <h3 title="ISBN-13">Bekannt aus unserem Magazin:</h3>
                                    {magazins.data.map((i, k) => <MagazinBox {...i.attributes} key={k} />)}
                                </BorderRadiusBox>

                                <BorderRadiusBox className="p-3 mb-3">
                                    <h3>{authorTitle}</h3>
                                    {authors.map((i, k) => <AuthorBox authorID={i.id} k={k} />)}
                                </BorderRadiusBox>

                                <BorderRadiusBox className="p-3 mb-3">
                                    <h3 title="ISBN-13">ISBN</h3>
                                    <p title={"ISBN-13: " + isbn13}>{isbn13}</p>
                                </BorderRadiusBox>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default BookPage