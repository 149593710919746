export function GenerateAntiXssLog() {

    //Wenn es ein Produktions-Build ist:
    if (process.env.NODE_ENV == "production") {
        //Logge anti XSS
        console.log(
            "\n\n\n\n%cSTOPP!",
            "color: #d93636; font-family:arial; font-weight: 600; font-size: 80px"
        );
        console.log(
            "%cInfos von reformleben;\n",
            "color: #ec1e85; font-family:arial; font-weight: 600; font-size: 28px"
        );
        console.log(
            "%cBitte füge keinen Code in diese Konsole ein, den du nicht verstehst!\n",
            "color: #f9deec; font-family:arial;font-weight: 600; font-size: 28px"
        );
        console.log(
            "%cHacker können über diese Konsole an persönliche Daten gelangen. Diesen Angriff nennt man 'XSS-Angriff'.",
            "font-family:arial;font-weight: 300; font-size: 22px"
        );
    }
}