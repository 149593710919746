import { AdBlockDetectedWrapper } from "adblock-detect-react"
import React, { useState } from "react"
import StaticImage from "../../../components/images/StaticImage"
import HeaderMenu from "../../../components/menus/Header/HeaderMenu/HeaderMenu"
import { hasValidAccessCode, isCodeValid, setAccessCodeInCookie } from "../../../services/adBlockerDetector/adBlockerDetector"
import "./AdBlockDetector.scss"


let WhitelistedLinks = ["/impressum", "/datenschutz", "/kontakt"]

function AdBlockDetector({ }) {



    const [Code, setCode] = useState("")


    function handleNewCode(e) {
        let newCode = e.target.value || ""

        setCode(newCode)
        if (isCodeValid(newCode)) {
            alert("Zugriffscode erfolgreich hinzugefügt.")
            setAccessCodeInCookie(newCode)
        }
    }

    let location = window.location.pathname
    if (WhitelistedLinks.includes(location)) return null  //Impressum, Kontakt usw
    if (hasValidAccessCode()) return null //Premium user
    return (
        <AdBlockDetectedWrapper>
            <div className="AdBlockDetector">
                <HeaderMenu hideSearchbar />

                <div className="top">
                    <div className="container">
                        <h1>Aufgrund Ihres Werbeblockers zeigen wir keine Inhalte an.</h1>
                        <h2>Zwei Optionen verfügbar:</h2>
                    </div>
                </div>

                <div className="container">


                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="box">
                                <div>
                                    <h3>Werbeblocker deaktivieren</h3>
                                    <hr />
                                </div>
                                <div className="d-flex justify-content-center m-5">
                                    <StaticImage className={"logo"} uri="/adblocker/logos.png" uriDarkMode="/logo/logo_dark.png" />
                                </div>
                                <div>
                                    <ol>
                                        <li>Klicken Sie oben rechts auf das AdBlocker-Symbol.</li>
                                        <li>Öffnen Sie die Einstellungen und deaktivieren Sie den AdBlocker für diese Website.</li>
                                        <li>Aktualisieren Sie diese Seite.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="box">
                                <div>
                                    <h3>reformleben online abonnieren</h3>
                                    <hr />
                                </div>
                                <div className="d-flex justify-content-center m-5">
                                    <StaticImage className={"logo"} uri="/logo/logo_light.png" uriDarkMode="/logo/logo_dark.png" />
                                </div>
                                <div>
                                    <p><b>Reformleben online</b> ist für alle, die unsere Neuigkeiten, Rezepte, Buchempfehlungen ohne personalisierte Werbung lesen möchten.  </p>
                                    <button style={{ opacity: 0.4 }} onClick={() => alert("Diese Funktion befindet sich noch in Entwicklung.")}>reformleben online abonnieren</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box mb-3">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <div>
                                <h3>Haben Sie schon ein Abo?</h3>
                                <p>Geben Sie Ihren Zugriffscode ein.</p>
                            </div>
                            <input placeholder="Zugriffscode" value={Code} onChange={handleNewCode} />
                        </div>
                    </div>

                    <div className="p-3">
                        <p>Bei technischen Problemen nutze bitte info@reformleben.de oder unsere Rückrufhotline unter +49 6082 922900 – 0.</p>
                    </div>

                    <div className="text-center mb-5">
                        <a href="/impressum">Impressum</a>
                        {" | "}
                        <a href="/datenschutz">Datenschutz</a>
                        {/* {" | "}
                        <Link to="/kontakt">Kontakt</Link> */}
                    </div>
                </div>
            </div>
        </AdBlockDetectedWrapper>
    )
}
export default AdBlockDetector