import React from "react"
import { Link } from "react-router-dom"
import { POST_ENDPOINT } from "../../../../const/Endpoints"
import { useApi } from "../../../../services/useApi"
import BorderRadiusBox from "../../../default/boxes/BorderRadiusBox/BorderRadiusBox"
import HTML from "../../../default/more/HtmlRenderer/HtmlRenderer"
import GhostLoader_TrendingBox from "../../../loader/GhostLoader/GhostLoader_TrendingBox/GhostLoader_TrendingBox"
import "./TrendingBox.scss"

let LIMIT = 3

function TrendingBox({ title, items }) {

    let { Data, Loading, Error } = useApi(POST_ENDPOINT + `/?per_page=${LIMIT}&offset=4`, true, 60 * 12);

    return (
        <BorderRadiusBox className="TrendingBox p-4">
            <i className="fa-solid fa-arrow-trend-up round"></i>
            <h3>{title}</h3>


            {Loading ?
                <GhostLoader_TrendingBox />
                :
                <>
                    {Error && <p>Die Trends konnten nicht geladen werden.</p>}
                    <div className="trendingBoxItems">
                        {Data && Array.isArray(Data) &&
                            Data.map((i, k) => <TrendingBoxItem key={k} number={k + 1} {...i} />)
                        }
                    </div>
                </>
            }
        </BorderRadiusBox>
    )
}
export default TrendingBox

function getMultipleRandom(arr, num) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
}

function TrendingBoxItem(articleData) {
    let { title, number, slug } = articleData
    return <Link
        className="TrendingBoxItem" to={"/p/" + slug}
        state={{ data: articleData }}
    >
        <span className="number">
            {number}.
        </span>
        {/* <img src={image} /> */}
        <h3><HTML>{title?.rendered}</HTML></h3>
    </Link>
}