import React, { useState } from "react"
import { Link } from "react-router-dom"
import BorderRadiusBox from "../../../components/default/boxes/BorderRadiusBox/BorderRadiusBox"
import ShareButtons from "../../../components/default/buttons/ShareButtons/ShareButtons"
import HTML from "../../../components/default/more/HtmlRenderer/HtmlRenderer"
import TextPage from "../../../components/default/pages/TextPage"
import MediaCarousel from "../../../components/default/strapi/MediaCarousel"
import RezeptePageInner from "../RezeptePage/RezeptePageInner"
import "./RezeptQrPage.scss"

function RezeptQrPageInner({ data }) {
    const [Amount, setAmount] = useState(data?.portions || 4)

    if (data) {
        let { portions, healthBecauseOf, ingredients, tipText, nutritionalValues, kcal, slug, categories, title, images } = data || {}

        let cookUrl = "/rezepte/" + slug + "/kochen"
        let breadcrumpsProps = {
            path: [
                {
                    title: "Rezepte",
                    uri: "/rezepte/"
                },
                {
                    title: data.title,
                },
            ]
        }

        function handleAmountChange(e) {
            setAmount(e.target.value)
        }

        let url = "https://Reformleben.de/rezepte/" + slug


        return (
            <TextPage
                metaTitle={title}
                metaDescription={`Weiterleitungseite vom QR-Code für das Rezept '${title}'.`}
                // title={data.title}
                className="RezeptQrPageInner"
                hideBreadcrumbs

            // headerImage={data.image}
            // useBottomTitle
            >


                <div className="row p-2">
                    {/* Author Box */}

                    <div className="mb-4">
                        <h3>Gescannter Artikel:</h3>
                        <Link to={"/rezept/" + slug} state={{ attributes: data }} >
                            <BorderRadiusBox className="p-0 articleBox">
                                <div className="row">
                                    <div className="col-4">
                                        <MediaCarousel data={images?.data || []} alt={title} />
                                        {/* <div className="img" style={{ backgroundImage: `url(${source_url})` }}>
                                        </div> */}
                                    </div>
                                    <div className="col-8 p-0">
                                        <div className=" p-0 textContent">
                                            <h1 className="m-0"><HTML>{title}</HTML></h1>
                                        </div>
                                    </div>
                                </div>
                            </BorderRadiusBox>
                        </Link>
                    </div>

                    {/* <div className="mb-4">
                        <h3>Über den Autor:</h3>
                        <BorderRadiusBox >
                            <AuthorBoxBig authorID={author} prefix={false} showSlogan />
                        </BorderRadiusBox>
                    </div> */}

                    <div className="mb-4">
                        <h3>Rezept teilen:</h3>
                        <BorderRadiusBox>
                            <ShareButtons heading={false} url={url} title={title} text={`Schau dir bitte dieses Rezept an: ${url}`} />
                        </BorderRadiusBox>
                    </div>

                    <div className="mb-5">
                        <h3>Weitere Rezepte:</h3>
                        <RezeptePageInner />
                        {/* So Filtern, das nicht der slebe Artikel nochmal Vorgeschlagen wird */}
                        {/* <NewsList hideArticle={slug} /> */}
                    </div>
                </div>
            </TextPage>
        )
    }
    return <p>Leeres Rezept</p>
}
export default RezeptQrPageInner

function handlePrint() {
    window.print();
}