import React from "react"
import { Link } from "react-router-dom"

function Url(props) {

    let { to, disableBlankTab } = props
    let className = "A " + props?.className?.toString()
    let renderProps = {
        ...props,
        className: className,
        to: to,
        href: to
    }



    if (to.includes("https://"))
        return (
            <a aria-label="Link öffnen" {...renderProps} target={disableBlankTab ? null : "_blank"} />
        )

    return (
        <Link aria-label="Link öffnen"  {...renderProps} />
    )

}
export default Url