import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import ShoppingList from "../../../components/box/rezepte/ShoppingList/ShoppingList"
import CounterInput from "../../../components/default/inputs/CounterInput/CounterInput"
import TextPage from "../../../components/default/pages/TextPage"
import "./RezeptCookPage.scss"

function RezeptCookPage({ }) {
    const navigate = useNavigate()
    const [Amount, setAmount] = useState(4)

    function handleAmountChange(e) {
        setAmount(e.target.value)
    }

    function navigateBack() {
        navigate(-1)
    }

    let slug = "konrad-brei"
    let cookUrl = `/rezepte/${slug}/kochen/schritt`

    return (
        <TextPage
            hideBreadcrumbs
            className="RezeptCookPage"
        >
            <div className="mt-4 pt-3">


                <button onClick={navigateBack} className="clear mb-5">
                    <h1><i className="fa-regular fa-left-long"></i> Konrad's Kartoffelpüree</h1>
                </button>

                <div className="d-flex flex-md-row flex-row-reverse flex-wrap">


                    <div className="col-12 col-lg-6  p-4">

                        <ShoppingList
                            amount={Amount}
                            header={<>
                                <span className="subHeading">
                                    <CounterInput value={Amount} onChange={handleAmountChange} />
                                    <span>Portionen</span>
                                </span>
                                <span className="heading">Zutaten: </span>
                            </>
                            }
                        />
                    </div>

                    <div className="col-12 col-lg-6 potionAmountSelection p-4">
                        <div>
                            <h2>Wie viele Portionen möchtest du kochen?</h2>

                            <div className="col-12">
                                <CounterInput value={Amount} onChange={handleAmountChange} />
                            </div>

                            <Link to={cookUrl}>
                                <button>Weiter</button>
                            </Link>
                        </div>
                    </div>


                </div>
            </div>
        </TextPage >
    )
}
export default RezeptCookPage
