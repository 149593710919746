import axios from "axios";
import { useEffect, useState } from "react";
import { STRAPI_API } from "../../const/Endpoints";
import { STRAPI_API_KEY } from "../../const/Keys";

const FALLBACK_IMAGE_SQUARE = "/uploads/medium_avatar_m_237c9495dd.jpg"
const FallbackImageObj = { data: { attributes: { url: FALLBACK_IMAGE_SQUARE } } }
const FallbackAuthor = { fullName: "Redaktion", slug: "redaktion", profileImage: FallbackImageObj, wordpressID: 18, fallback: true }
export function useAuthor(wordpressID) {
    let endpoint = "/authors?filters[wordpressID][$eq]=" + wordpressID + "&populate=*"
    const [Data, setData] = useState(null)
    const [Error, setError] = useState(null)
    const [Loading, setLoading] = useState(false)

    useEffect(() => {
        (
            async function () {
                setError(false)
                setLoading(true)

                let config = {
                    method: 'get',
                    url: STRAPI_API + endpoint,
                    headers: {
                        'Authorization': `Bearer ${STRAPI_API_KEY}`
                    }
                };
                axios(config)
                    .then(res => {
                        let authorArray = res?.data?.data
                        let author = FallbackAuthor
                        if (Array.isArray(authorArray) && authorArray.length > 0) {
                            author = authorArray[0]?.attributes
                        }
                        setData(author)
                    })
                    .catch(() => { setError(true); console.log("err") })
                    .finally(() => setLoading(false))
            }
        )()
    }, [])

    return { Data, Loading, Error }
}