import React from "react"
import { Link } from "react-router-dom"
import BoxPage from "../../../components/default/pages/BoxPage/BoxPage"
import StaticImage from "../../../components/images/StaticImage"
import "./MediaDataPage.scss"

function MediaDataPage({ }) {


    return <div className="MediaDataPage" >
        <BoxPage boxes={boxes} />
    </div >
}
export default MediaDataPage

const themen = [
    {
        title: "Gesundheit",
        desc: "reformleben ist Ratgeber für die Themen Gesunderhaltung und -vorsorge.",
        image: null,
    },
    {
        title: "Ernährung & Rezepte",
        desc: "Vielfältige Infos und raffinierte Rezepte für eine bewusste Ernährung.",
        image: null,
    },
    {
        title: "Fitness & Sport",
        desc: "Tipps, Trends und fundierte Fachar-tikel rund um ein aktives Leben.",
        image: null,
    },

    {
        title: "Schönheit & Kosmetik",
        desc: "Inspirationen von Beauty-Experten für die rein natürliche Körperpflege.",
        image: null,
    },

    {
        title: "Lifestyle",
        desc: "Hier geht’s um die schönen Dinge des Lebens wie Reisen, Mode oder Wohnen.",
        image: null,
    },
]

const boxes = [
    {
        type: "WELCOME",
        payload: {
            title: "Meidadaten",
            subTitle: "Alle Infos für Werbende",
            image: "/magazin/reMormLeben_offen_drMohr-basisprogramm.jpg",
        },
    },
    {
        type: "WelcomeTextBox",
        payload: {
            title: <>Das Magazin</>,
            text: <>
                <h3>INSPIRATION UND BEGLEITUNG </h3>
                <p>Aktuelles Wissen rund um die natürliche Gesund- erhaltung – das bietet seit 2015 das unabhängige Magazin reformleben. Hauptaugenmerkt liegt auf der Produktwelt des Reformhauses und dem Be- dürfnis gesundheitsbewusster Verbraucher nach fundierten Informationen. Mit seinem frischen Design, vielfältigen Themenspektrum und medi- zinischen Background versteht sich das Magazin als perfekte Ergänzung zur Fachberatung im Reformhaus und als Partner des Fachhandels. Für Inserenten ein attraktives Werbeumfeld!</p>
                <h3>PHILOSOPHIE</h3>
                <p>Für reformleben schreiben ausgewiesene Exper- ten ihres Fachs. Immer mit dabei: Dr. med Klaus Mohr, Begründer des wegweisenden „Dr. Mohr Basisprogramm – für längere Gesundheit“. Durch seriöse, informative und medizinisch fundierte Beiträge, die emotional ansprechen, schafft reformleben ein besonderes Vertrauen, dass sich auf die Marken, die in ihr werben, überträgt.</p>
                <h3>UNSERE LESER</h3>
                <p>reformleben richtet sich an Reformhaus-Kunden jeden Alters. Unsere Leser sind überwiegend weiblich, gebildet und befinden sich in einer ak- tiven, kaufkräftigen Lebensphase. Zu den wich- tigsten Zielen und Werten der Zielgruppe zählen Gesundheit, bewusste Ernährung, Nachhaltigkeit und Qualität. Der Anspruch an kompetente und verlässliche Informationen ist hoch – gut, dass wir hier locker mithalten können.</p>
            </>,
            image: "/assets/image/magazin/reform-leben-paket.jpg",
        },
    },
    //Verlag Kontaktdaten & Co 
    {
        type: "Custom",
        payload: <div className="container p-5">
            <h2>Kontakt</h2>


            <div className="row">
                <div className="col-12 col-lg-6 p-1 pb-5">
                    <StaticImage uri="/persons/BernhardSillich.jpg" />
                </div>
                <div className="col-12 col-lg-6">
                    <h3>Ihr Ansprechspartner</h3>
                    <p>Bernhard Sillich</p>
                    <p>zoe Media-Verlag GmbH<br />Brunhildestr. 34<br />61389 Schmitten</p>
                    <p>T 06082.922900-0<br />F 06082.922900-9<br />bernhard.sillich@reformleben.de</p>
                </div>
                <div className="col-12 col-lg-6">
                    <h3>VERLAG UND REDAKTION</h3>
                    <p>zoe Media-Verlag GmbH
                        Brunhildestr. 34<br />
                        61389 Schmitten<br />
                        <br />
                        <a href="tel:4960829229000">T 06082 922900 - 0</a><br />
                        <a href="mailto:info@reformleben.de">info@reformleben.de</a><br />
                        <Link to="/">https://shop.reformleben.de</Link><br />
                        <br />
                        Geschäftsführer Bernhard Sillich Registergericht<br />Amtsgericht Königstein<br />Registernummer HRB 9614<br />
                        USt.-ID DE 298 300 270
                    </p>
                </div>
                <div className="col-12 col-lg-6">
                    <div>
                        <h3>BANKVERBINDUNG</h3>
                        <p>zoeMedia Verlag GmbH · Sparkasse Giessen<br />IBAN: DE15513500250205041272<br />BIC: SKGIDE5FXXX</p>
                    </div>

                    <div>
                        <h3>GESCHÄFTSBEDINGUNGEN</h3>
                        <p>Es gelten die allgemeinen Geschäftsbedingungen für Anzeigen und Beilagen sowie die des Verlages.</p>
                    </div>

                    <div>
                        <h3>ZAHLUNGSBEDINGUNGEN</h3>
                        <p>Zahlung innerhalb von 14 Tagen nach Rechnungs- datum netto (ohne Abzüge). Auslandsaufträge nur gegen Vorkasse.</p>
                    </div>
                </div>
            </div>


        </div>
    },
    // Themen
    {
        type: "Custom",
        payload: <div className="container p-5 mt-5 mb-5">
            <h2>Themen & Rubriken</h2>

            <p>Jede Ausgabe von reformleben widmet sich einem speziellen Gesundheitsthema u. a. mit Bezug zum „Dr. Mohr Basisprogramm“. Neben dem Schwerpunktthema gibt es fünf feste Rubriken rund um Gesundheit, Ernährung und Wohlbefinden: </p>

            <div className="row">
                {themen.map((i, k) => <div key={k}>
                    <h3>{i.title}</h3>
                    <p>{i.desc}</p>
                </div>)}
            </div>
        </div>
    },


    {
        type: "WelcomeTextBox",
        payload: {
            title: <>Medialeistung</>,
            text: <>
                <div className="mb-2">
                    <h3>Druckauflage</h3>
                    <p>
                        110.000 Exemplare<br />
                        Deutschland, teilweise Österreich und Schweiz
                    </p>
                </div>

                <div className="mb-2">
                    <h3>Erscheinungsweise</h3>
                    <p>
                        6x Jährlich<br />
                        Print-Magazin
                    </p>
                </div>


                <div className="mb-2">
                    <h3>Reichweite</h3>
                    <p>ca. 2,5 Leser pro Ausgabe</p>
                </div>

                <div className="mb-2">
                    <h3>Anzeigenformate</h3>
                    <p>2/1 Doppelseite<br />
                        1/1 Seite<br />
                        U2 (Umschlag-Innenseite)<br />
                        U3 (Umschlag-Innenseite)<br />
                        U4 (Umschlag-Außenseite)<br />
                        1/2 Seite<br />
                        1/4 Seite</p>
                </div>

                <div className="mb-2">
                    <h3>SONDERINSERTIONEN</h3>
                    <p>Beilagen<br />
                        Beikleber<br />
                        Sonderdruck (auf Anfrage)
                    </p>
                </div>
            </>,
            image: "/assets/image/magazin/reform-leben-paket.jpg",
        },
    },
    {
        type: "Custom",
        payload: <div className="container p-5">
            <h3>Ausgaben & Termine</h3>
            <p>Infos auf Anfrage. Webversion folgt zeitnah.</p>
        </div>
    },
    {
        type: "Custom",
        payload: <div className="container p-5">
            <h3>Anzeigenpreise & Formate</h3>
            <p>Infos auf Anfrage. Webversion folgt zeitnah.</p>
        </div>
    },
    {
        type: "Custom",
        payload: <div className="container p-5">
            <h3>SONDERINSERTIONEN</h3>
            <p>Infos auf Anfrage. Webversion folgt zeitnah.</p>
        </div>
    },
    {
        type: "Custom",
        payload: <div className="container p-5">
            <h3>Druckdatenerstellung</h3>
            <p>Infos auf Anfrage. Webversion folgt zeitnah. <a target="_blank" href="https://dashdesign.eu">Kontakt</a></p>
        </div>
    },
    {
        type: "Custom",
        payload: <div className="container p-5">
            <h3>Allgemeine Geschäftsbedingungen</h3>
            <small>
                Es gelten die allgemeinen Geschäftsbedingungen<br />
                für Anzeigen und Fremdbeilagen in Zeitungen und Zeitschriften.<br />
                <br />
                Zusätzliche Geschäftsbedingungen des Verlages:<br />
                1. Anzeigenabschlüsse sind innerhalb eines Jahres,<br />
                ab dem Erscheinen der ersten Anzeige, zu erfüllen.<br />
                <br />
                2. Die Gewährung eines Nachlasses setzt voraus, dass zu Beginn der Insertion ein laut Preisliste nachlassfähiger Abschluss erteilt worden ist. Text- und Motivwechsel sind möglich.<br />
                <br />
                3. Bei Auftragserweiterung im Laufe des Jahres wird der sich ergebende Nachlass für die bisher erschienenen Anzeigen nachvergütet, bei Auftragsstornierung erfolgt entsprechende Nachbelastung. Auftragsstornierung ist nur bis zum jeweiligen Anzeigenschlusstermin möglich.
                <br /><br />
                4. Für die Aufnahme von Anzeigen in bestimmten Ausgaben oder an bestimmten Plätzen wird keine Gewähr übernommen. Anzeigen, die aufgrund
                ihrer redaktionellen Gestaltung nicht als Anzeigen erkennbar sind, werden als solche vom Verlag mit dem Wort „Anzeige“ oder in anderer geeigneter
                Form deutlich gemacht.
                <br /><br />
                5. Der Verlag behält sich vor, Anzeigen oder Beilagen auch nach erfolgter Auftragserteilung zurückzu-
                weisen, falls die Redaktion Widerspruch erhebt.
                <br /><br />
                6. Der Verlag gewährleistet die drucktechnisch einwandfreie Wiedergabe der Anzeige. Ungeeignete oder beschädigte Druckdaten werden dem Auftraggeber unverzüglich zurückgesandt. Der Auftraggeber ist bei ganz oder teilweise unleserlichem, unrichtigem oder unvollständigem Abdruck der Anzeige zu einer Ersatzanzeige berechtigt, es sei denn, dass durch die Mängel der Zweck der Anzeige unerheblich beeinträchtigt wird; fehlerhaft gedruckte Kennziffern beeinträchtigen den Zweck der Anzeige nur uner-
                heblich. Anspruch auf Schadenersatz besteht nicht.
                <br /><br />
                7. Für Mängel der vom Auftraggeber eingereichten
                Druckunterlagen bzw. -daten , die nicht sofort erkennbar und erst beim Druckvorgang auffallen, übernimmt der Verlag keine Haftung.
                <br /><br />
                8. Probeabzüge werden nur auf ausdrücklichen Wunsch geliefert. Der Auftraggeber trägt die Verantwortung für die Richtigkeit der zurückgesandten Abzüge. Sendet der Auftraggeber den ihm rechtzeitig übermittelten Probeabzug nicht fristgemäß zurück, so gilt die Druckfreigabe als erteilt.
                <br /><br />
                9. Der Verlag liefert nach Erscheinen der Anzeige
                ein kostenfreies Belegexemplar.
                <br /><br />
                10. Zahlungsbedingungen: Innerhalb von 14 Tagen
                nach Rechnungsdatum ohne Abzüge.
                <br /><br />
                11. Bei Zahlungsverzug oder Stundung werden Zinsen in Höhe von 5 % über dem Basiszinssatz sowie die Einziehungskosten berechnet; der Verlag kann die Ausführung des Auftrages bis zur vollständigen Bezahlung zurückstellen.
                <br /><br />
                12. Kosten für Änderungen ursprünglich vereinbarter Ausführungen trägt der Auftraggeber.
                <br /><br />
                13. Einwendungen aller Art sind innerhalb von 8 Tagen nach Rechnungsdatum zu erheben.
                <br /><br />
                14. Werbungsmittler und Werbeagenturen sind verpflichtet, sich in ihren Angeboten, Verträgen und Abrechnungen mit den Werbungstreibenden an die aktuelle Preisliste des Verlages zu halten. Die vom Verlag gewährte Mittlungsvergütung darf an die Auftraggeber weder ganz noch teilweise weiter-
                gegeben werden.
                <br /><br />
                15. Eine Änderung der Anzeigenpreise gilt ab Inkrafttreten auch für laufende Aufträge, nicht jedoch vor Ablauf von 3 Monaten nach Bekanntgabe.
                <br /><br />
                16. Der Auftraggeber trägt allein die Verantwortung für den Inhalt und die rechtliche Zulässigkeit der für die Insertion zur Verfügung gestellten Text und Bildunterlagen. Dem Auftraggeber obliegt es, den Verlag von Ansprüchen Dritter freizustellen, die diesen aus der Ausführung des Auftrages, auch wenn er storniert sein sollte, gegen den Verlag erwachsen. Der Verlag ist nicht verpflichtet, Aufträge und Anzeigen daraufhin zu prüfen, ob durch sie Rechte Dritter beeinträchtigt werden.
                <br /><br />
                17. Erfüllungsort: Schmitten, Gerichtsstand: Königstein
                <br /><br /><br />
                <b>zoeMedia Verlag GmbH</b><br />
                Brunhildestr. 34 · 61389 Schmitten<br />
                T 06082.922900-0 · F 06082. 922900-9<br />
                info@reformleben.de ·<Link to="/">reformleben.de</Link><br />
            </small>
        </div>
    },

]
