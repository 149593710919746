import React from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import BookBox from "../../../components/box/BookBox/BookBox";
import AdBanner from "../../../components/box/ad/AdBanner/AdBanner";
import AuthorBoxBig from "../../../components/box/news/AuthorBoxBig/AuthorBoxBig";
import RezeptPageItem from "../../../components/box/rezepte/RezeptPageItem";
import BorderRadiusBox from "../../../components/default/boxes/BorderRadiusBox/BorderRadiusBox";
import StrapiImage from "../../../components/default/strapi/StrapiImage/StrapiImage";
import GhostLoader_Article from "../../../components/loader/GhostLoader_Article";
import { STRAPI } from "../../../const/Endpoints";
import { FormartToEuro } from "../../../functions/formartToEuro";
import { useApi } from "../../../services/useApi";
import "./MagazineItemPage.scss";

function MagazineItemPage({ }) {
    let { id } = useParams();

    let { Data, Loading, Error } = useApi("/magazins?populate[0]=authors&populate[1]=books&populate[2]=books.coverImage&populate[3]=cover&populate[4]=recipes&populate[5]=recipes.images&populate[6]=webPdf&filters[countNumber][$eq]=" + id, false, 0)
    if (Error) return <p>Fehler: Magazin nicht geladen.</p>
    if (Loading) return <GhostLoader_Article />
    if (Data?.data.length == 0) return <p>Keine Magazine.</p>
    if (Data) {

        console.log(Data.data[0].attributes)

        let { numberString, releaseDate, cover, shopUrl, authors, content, description, title, nrText, price, priceText, recipes = { data: [] }, pages, showOnPublicSite, webPdf, countNumber, books = [] } = Data.data[0].attributes


        recipes = recipes.data

        const ldJson = {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": `${title} | reformleben Print Ausgabe ${countNumber || "X"}`,
            "image": cover,
            "description": description,
            "sku": countNumber,
            "offers": {
                "@type": "Offer",
                "url": shopUrl,
                "priceCurrency": "EUR",
                "price": price,
                "availability": "http://schema.org/InStock",
                "priceValidUntil": releaseDate,
                "seller": {
                    "@type": "Organization",
                    "name": "reformleben",
                    "url": "https://reformleben.de"
                }
            },
        };

        let webPdfSrc = webPdf.data.attributes.url

        return (
            <div className="MagazineItemPage">
                <Helmet>
                    {JSON.stringify(ldJson)}
                </Helmet>
                <div className="mt-2">
                    {/* <h1>Magazin</h1> */}



                    <div className="box box-header">
                        <div className="container pb-5 pt-5">
                            <div>
                                <div className="mb-5">
                                    <h1><br /><span className="black---">{title}</span></h1>
                                    <br />
                                    <span>Ausgabe {numberString}</span>
                                </div>

                            </div>



                            <div className="row align-items-center">
                                <div className=" col-12 col-md-3">
                                    <StrapiImage image={cover} className={"cover"} />
                                </div>

                                <div className=" col-12 col-md-5 content">
                                    <span className="detail price">{FormartToEuro(price)}</span>
                                    <span className="detail">{priceText}</span>

                                    <hr />
                                    <span className="detail">Ausgabe {numberString}</span>
                                    <span className="detail">Seitenanzahl: {pages}</span>
                                    <span className="detail">Erschienen: {releaseDate}</span>


                                </div>


                                <div className="col-12 col-md-4">
                                    <div>
                                        <Link to="/reformhäuser/" target="_blank" >
                                            <button className="sub">
                                                <i className="fa-solid fa-search"></i>
                                                <span>
                                                    Reformhaus finden
                                                </span>
                                            </button>
                                        </Link>
                                        <a href={shopUrl} target="_blank" >
                                            <button className="">
                                                <i className="fa-solid fa-cart-shopping-fast"></i>
                                                <span>Online bestellen</span>
                                            </button>
                                        </a>
                                        <a href={STRAPI + webPdfSrc} target="_blank" >
                                            <button className="main">
                                                <i className="fa-solid fa-file-pdf"></i>
                                                <span>Online lesen</span>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className=" col-12 col-md-5 content pt-5 pb-5">
                        <AdBanner
                            slot="9410520388"
                            format="fluid"
                            layoutKey="-60+cc-5-4s+n0"
                            style={{ minHeight: 500 }}
                        />
                    </div> */}



                    {/* Bereich mit Details */}
                    <div className="container mt-5">

                        <h2>Details</h2>

                        <div className="row">
                            <div className=" col-12 col-md-8 content">

                                <div className="mb-5">
                                    <h3>Beschreibung</h3>
                                    <p>
                                        {description}
                                    </p>
                                </div>
                                <div className="mb-5">
                                    <h3>Rezepte</h3>
                                    <p>Unsere Top-Rezepte aus der {countNumber}. Ausgabe:</p>
                                    <div className="col-12">
                                        <div className="row">
                                            {recipes.map((i, k) =>
                                                <RezeptPageItem {...i} key={k} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <h3>Artikel</h3>
                                    <p>Diese Funktion ist noch nicht verfügbar.</p>
                                    <div className="col-12">

                                        {/* <div className="row">
                                            {recipes.map((i, k) =>
                                                <RezeptPageItem {...i} key={k} />
                                            )}
                                        </div>
                                    </div> */}
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <h3>Inhalt</h3>
                                    <p>
                                        {content}
                                    </p>
                                </div>

                            </div>


                            <div className=" col-12 col-md-4">
                                <BorderRadiusBox className="p-3">
                                    <h3>Buchempfehlungen</h3>
                                    <p>In der Ausgabe {id} haben wir die folgenden Bücher vorgestellt:</p>
                                    {books.data.map((i, k) => <BookBox {...i.attributes} key={k} />)}


                                    <div className="mt-4">
                                        <Link className="my-4" to="/buchempfehlungen/">
                                            Weitere Buchempfehlungen <i className="fa-regular fa-chevron-right"></i>
                                        </Link>
                                    </div>
                                </BorderRadiusBox>
                                <AdBanner />
                                <BorderRadiusBox className="p-3">
                                    <h3>Autoren</h3>
                                    {authors.data.map((i, k) => <AuthorBoxBig authorID={i.id} />)}
                                </BorderRadiusBox>
                                <AdBanner />
                            </div>
                        </div>
                    </div>



                </div>
            </div >
        )
    }
}
export default MagazineItemPage