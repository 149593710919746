import React from "react"
import { STRAPI } from "../../../../const/Endpoints"
import "./StrapiImage.scss"

function StrapiImage({ image, className }) {
    let src = GetStrapiImageUrl(image)
    let alt = GetStrapiImageAlt(image)
    return (
        <div className={"StrapiImage " + className}>
            <img className="w-100" src={src} alt={alt} />
        </div>
    )
}
export default StrapiImage

export function GetStrapiImageAlt(image) {
    if (!image) return ""
    let img = image?.data?.attributes || image?.attributes || {}
    let { alternativeText } = img
    return alternativeText
}
export function GetStrapiImageUrl(image, size = "medium") {
    if (!image) return ""
    let img = image?.data?.attributes || image?.attributes || {}
    let { name, alternativeText, caption, formats, url } = img

    if (!url) return ""

    let srcSize = formats ? formats[size] : undefined
    let src = srcSize?.url || url
    src = STRAPI + src

    return src
}