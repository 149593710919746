import React from "react"
import { Link } from "react-router-dom"
import { STRAPI } from "../../../../const/Endpoints"
import BorderRadiusBox from "../../../default/boxes/BorderRadiusBox/BorderRadiusBox"
import StaticImage from "../../../images/StaticImage"
import "./NewsItemTile.scss"

function NewsItemTile({ lableTitle, title, description, image, uri }) {
    let src = image?.data?.attributes?.formats?.medium?.url || image?.data?.attributes?.formart

    if (src) {
        src = STRAPI + src
    }

    return (
        <Link to={uri}>
            <BorderRadiusBox innerBorderRadius className="NewsItemTile" >
                <StaticImage asBackground url={src} >

                    <div className="content">
                        <div className="col-12 px-4">
                            {lableTitle && <span className="label">{lableTitle}</span>}
                            {title && <span className="title">{title}</span>}
                            {description && <span className="desc">{description}</span>}
                        </div>
                    </div>
                </StaticImage>
            </BorderRadiusBox>
        </Link>
    )
}
export default NewsItemTile