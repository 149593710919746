import React from "react"
import StrapiImage from "../../default/strapi/StrapiImage/StrapiImage"
import "./ProfileImage.scss"

function ProfileImage({ initiales, strapiImageObj, size = 40 }) {
    let result

    if (strapiImageObj) {
        result = <StrapiImage image={strapiImageObj} />
    }
    else if (strapiImageObj) {
        let initialsLength = initiales.length
        let fontSizeFactor = 1.6 + initialsLength * 0.4 //Die Schrift dür jedes weitere Zeichen keliner
        let fontSize = size / fontSizeFactor
        result = <span className="initiales" style={{ fontSize: fontSize }}>{initiales}</span>

    } else {
        return <i className="fa-solid fa-user" />
    }


    return (
        <div className="ProfileImage">
            {result}
        </div>
    )
}
export default ProfileImage