import React from "react"
import { Link } from "react-router-dom"
import formatTime from "../../../functions/formartTime"
import BorderRadiusBox from "../../default/boxes/BorderRadiusBox/BorderRadiusBox"
import HTML from "../../default/more/HtmlRenderer/HtmlRenderer"
import NewsImage from "../../images/NewsImage/NewsImage"
import AuthorBox from "./AuthorBox"
import "./NewsItem.scss"

function NewsItem(newsItem) {

    let {
        id,
        date,
        date_gmt,
        slug,
        type,
        link,
        title,
        excerpt,
        content,
        author,
        categories,
        better_featured_image,
        className,
    } = newsItem

    let jsDate = new Date(date)
    let dateString = formatTime(jsDate)

    title = title?.rendered || ""
    excerpt = excerpt?.rendered || ""

    const wordsPerMinute = 200; // Durchschnittliche Lesegeschwindigkeit in Wörtern pro Minute
    const wordCount = content.rendered.trim().split(/\s+/g).length;
    const readingTime = Math.ceil(wordCount / wordsPerMinute);

    return (
        <Link className={className + " NewsItem"} to={"/p/" + slug} state={{ data: newsItem }} >
            <article>


                <BorderRadiusBox className="inner d-flex flex-row p-0 m-0  p-3">

                    <div className="col">




                        <div className="mb-2">
                            <h3 className="mb-0"><HTML>{title}</HTML></h3>
                            <AuthorBox textclassName="d-flex" noImage authorID={author} dateString={dateString} />
                        </div>

                        <div className="excerpt">
                            <HTML>{excerpt || "Kein Auszug"}</HTML>
                        </div>

                        <div>
                            <span className="readingTime">
                                <i className="fa-regular fa-file-lines"></i> {readingTime} Min
                            </span>
                        </div>
                    </div>


                    <NewsImage alt={title} betterImage={better_featured_image} wrapperClassName="col" />

                </BorderRadiusBox>
            </article>
        </Link>
    )
}
export default NewsItem