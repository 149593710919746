import React from "react"
import GhostLoader from "./GhostLoader"
import "./GhostLoader_Text.scss"

function GhostLoader_Text({ words = 32, height = 16, borderRadius = 6 }) {

    let wordsArray = []
    for (let i = 0; i < words; i++) {
        let randomWordWidth = getRandomInt(20, 128)
        wordsArray.push(randomWordWidth)
    }

    return (
        <div className="GhostLoader_Text">
            {wordsArray.map((i, k) =>
                <GhostLoader
                    width={i}
                    height={height}
                    key={k}
                    borderRadius={borderRadius}
                />)}
        </div>
    )
}
export default GhostLoader_Text

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}