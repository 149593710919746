import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useRecipe } from "../../../services/recipes/useRecipe";
import "./RezeptQrPage.scss";
import RezeptQrPageInner from "./RezeptQrPageInner";

function RezeptQrPage({ }) {
    let location = useLocation()
    let { attributes } = location?.state || {}
    let { slug } = useParams();

    if (attributes) return <RezeptQrPageInner data={attributes || {}} />

    const F = () => {
        let { Data, Loading, Error } = useRecipe(slug)
        if (Loading) return <p>Lädt...</p>
        if (Error) return <p>Error: {Error} on slug: {slug}</p>
        if (Data) {
            return <RezeptQrPageInner data={Data?.data?.data[0].attributes || {}} />
        }
    }
    return <F />
}
export default RezeptQrPage
