import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import TextPage from "../../components/default/pages/TextPage";
import StrapiImage from "../../components/default/strapi/StrapiImage/StrapiImage";
import GhostLoader from "../../components/loader/GhostLoader";
import { STRAPI } from "../../const/Endpoints";
import { useAuthor } from "../../services/author/useAuthorHook";
import NetworkErrorPage from "../more/NetworkErrorPage/NetworkErrorPage";
import Page404 from "../more/PageRedirectOr404/PageRedirectOr404";
import "./AuthorPage.scss";

function temp() {
    async function getUsers() {
        const response = await fetch('https://shop.reformleben.de/wp-json/wp/v2/users');
        const data = await response.json();

        return data.map(user => ({
            fullName: `${user.name.first} ${user.name.last}`,
            website: user.website,
            description: user.description,
            email: user.email
        }));
    }

    let apiKey = "692b0e59f700d294428bebf6b45127031c6a8ee47106d3678fe353a338e8f3c30c50e7bff3533f9e07a0dac1eb2708921df33469f925266682fc2f62261e6116d614841e5ed588c4757fe40b58cafe46c25d61fa9388710c768d65c1670074767fd8ded99543e83b9bf4748be0a3035a1f53972e4f286187a6f033d1ff97f33a"
    getUsers().forEach((u) => {
        axios({
            method: "POST",
            body: JSON.stringify(u),
            'Authorization': `Bearer ${apiKey}`
        })
    })
}


function AuthorPage({ }) {

    let { id, fullNameIrrelevant } = useParams();
    let { Data, Loading, Error } = useAuthor(id)

    if (!id) return <Page404 />
    if (Loading) return <GhostLoader width={"80vw"} height={"50vh"} borderRadius={4} />
    if (Error) return <NetworkErrorPage error={Error} />
    if (Data) {


        let { fullName, slug, description, website, slogan, email, instagram, facebook, bannerImage, profileImage, wordpressID
        } = Data
        slug = "@" + slug
        let breadcrumpsProps = {
            path: [
                {
                    title: "Autor:in",
                    to: null
                },
                {
                    title: slug,
                    to: "/"
                }
            ]
        }

        console.log(profileImage)

        let bannerUri = bannerImage.data?.attributes?.formats?.medium?.url || null
        console.log(bannerUri)


        if (!email) email = "info@reformleben.de"

        return (
            <TextPage
                useBottomTitle
                title={fullName}
                headerImage={bannerUri ? STRAPI + bannerUri : "/more/datenschutzInDeutschland.jpg"}
                className="AuthorPage "
                breadcrumpsProps={breadcrumpsProps}
            >
                {/* <button onClick={temp}>Umzug</button> */}

                <div className="mb-5">
                    <p style={{ fontSize: 22, marginTop: -16, }}>{slogan}</p>
                </div>

                <div className=" d-flex flex-column flex-md-row align-items-center w-100">
                    <StrapiImage className={"profileImage"} image={profileImage} />
                    <div className="p-5">
                        <h2>Über {fullName}</h2>
                        <p>{description || "Für " + fullName + " ist noch keine Beschreibung verfügbar."}</p>
                    </div>
                </div>

                <div className="mt-5">
                    <h2>Kontaktdaten</h2>

                    <div>
                        <h3>Email Adresse:</h3>
                        <p>{email}</p>
                    </div>

                    {website &&
                        <a href={website} target="_blank">
                            <h3>Website</h3>
                            <p>{website}</p>
                        </a>
                    }


                    {instagram &&
                        <a href={`https://instagram.com/${instagram}`} target="_blank" rel="nofollow">
                            <h3>Instagram</h3>
                            <p>@{instagram}</p>
                        </a>
                    }

                    {facebook &&
                        <a href={facebook} target="_blank" rel="nofollow">
                            <h3>Facebook</h3>
                            <p>{fullName}</p>
                        </a>
                    }

                </div>
            </TextPage >
        )
    }
}
export default AuthorPage