import React from "react"
import "./ErrorText.scss"

function ErrorText({ }) {
    return (
        <div className="ErrorText">
            <p>Fehler beim laden dieser Seite.</p>
        </div>
    )
}
export default ErrorText