import React from "react"
import "./StatisticBox.scss"

function StatisticBox({ title }) {
    return (
        <div className="StatisticBox">
            <div className="container">

                <h2>Stats</h2>

                <div>

                </div>
            </div>
        </div>
    )
}
export default StatisticBox